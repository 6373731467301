<template>
  <div
    :class="{
      'bg-gray-200': (imageUrl || !label) && !backgroundColor,
      'bg-primary-default': (imageUrl || label) && !backgroundColor,
      'h-10 w-10': size === '10',
      'h-12 w-12': size === '12'
    }"
    :style="{
      'background-color': backgroundColor
    }"
    class="relative flex items-center justify-center rounded-full text-xs font-semibold text-white transition-colors"
  >
    <img
      v-if="imageUrl"
      :class="{
        'h-10 w-10': size === '10',
        'h-12 w-12': size === '12'
      }"
      :src="imageUrl"
      class="rounded-full"
    />
    <span v-else-if="label">{{ label }}</span>
    <span
      v-if="status"
      :class="status === 'idle' ? 'bg-orange-400' : 'bg-green-400'"
      class="absolute left-0 top-0 -ml-1 -mt-1 h-4 w-4 rounded-full border-2 border-white"
    />
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "10"
    },
    status: {
      type: String,
      default: null
    }
  }
}
</script>
