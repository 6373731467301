import { render, staticRenderFns } from "./[id].vue?vue&type=template&id=49801036&"
import script from "./[id].vue?vue&type=script&lang=js&"
export * from "./[id].vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports