<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Account Types", endpoint="account-types", :sort="{name: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newAccountType()") NEW ACCOUNT TYPE
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Name", sortable="name"): router-link(:to="{name: 'account-types.view', params: {id: row.id}}") {{ row.name }}
          DataTableColumn(title="User Templates", sortable="user_templates_count") {{ row.user_templates_count | number }}
          template(v-slot:actions): router-link(:to="{name: 'account-types.view', params: {id: row.id}}"): Icon(icon="chevron-right")
</template>

<script>
import NewAccountTypeModal from "@/modals/AccountTypes/NewAccountTypeModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Account Types"
  }),
  methods: {
    async newAccountType() {
      if (await this.$modal.show(NewAccountTypeModal)) {
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>
