<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Libraries", endpoint="libraries", :filters="filters", :sort="{ name: 'asc' }", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newLibrary()") NEW LIBRARY
      template(v-slot:filters)
        DataTableFilter(v-model="filters.owner", endpoint="options/partners", label="Owner", prepend-option="All Owners")
        DataTableFilter(v-model="filters.partner", endpoint="options/partners", label="Partner Access", prepend-option="All Partners")
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Name", sortable="name")
            a(@click="editLibrary(row.id)") {{ row.name }}
          DataTableColumn(icon="content", sortable="content_count", sort-order="desc", width="max") {{ row.content_count | number | default('0') }}
          DataTableColumn(title="Owner", icon="users")
            router-link(v-if="row.owner", :to="{name: 'partners.view', params: {id: row.owner.id}}") {{ row.owner.name }}
            span(v-else) -
          DataTableColumn(title="Partner Access", icon="users")
            ul.list-comma-separated: li(v-for="partner in row.partners") {{ partner.name }}
            span(v-if="!row.partners.length") -
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editLibrary(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteLibrary(row)") Delete
</template>

<script>
import NewLibraryModal from "@/modals/Libraries/NewLibraryModal"
import EditLibraryModal from "@/modals/Libraries/EditLibraryModal"

export default {
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-libraries" } }
  },
  data: () => ({
    title: "Libraries",
    filters: {}
  }),
  methods: {
    async newLibrary() {
      if (await this.$modal.show(NewLibraryModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editLibrary(id) {
      if (await this.$modal.show(EditLibraryModal, { endpoint: `libraries/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteLibrary(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`libraries/${row.id}`)) {
          this.$notification.success(`Library **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>

<style>
@screen lg {
  .page-libraries {
    .datatable__column {
      @apply items-start;

      &.is-actions .dropdown {
        @apply mt-1;
      }
    }
  }
}
</style>
