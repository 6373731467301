<template lang="pug">
  AppLayout(:title="title")
    DataTable(title="Content Added to User Collections", endpoint="dashboard/content-added-to-collections")
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Content")
            div.flex.space-x-4.items-center
              BlockTypeIcon(:type="row.content.block_type")
              router-link(:to="{name: 'content.edit', params: {id: row.content.id}}") {{ row.content.title }}
          DataTableColumn(title="Collection"): router-link(:to="{name: 'collections.view', params: {id: row.collection.id}}") {{ row.collection.name }}
          DataTableColumn(title="Added", width="max") {{ row.added_at | datetime }}

    DataTable(title="New User Content", endpoint="dashboard/content-added-by-users")
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Content", sortable="title")
            div.flex.space-x-4.items-center
              BlockTypeIcon(:type="row.block_type")
              router-link(:to="{name: 'content.edit', params: {id: row.id}}") {{ row.title }}
          DataTableColumn(title="User", sortable="title")
            p: router-link(:to="{name: 'users.view', params: {id: row.user.id}}") {{ row.user.name }}
            p: router-link(:to="{name: 'partners.view', params: {id: row.partner.id}}", class="text-supplement") {{ row.partner.name }}
          DataTableColumn(title="Added", width="max") {{ row.created_at | datetime }}

    DataTable(title="No Result Searches", subtitle="within the last month", endpoint="dashboard/searches-with-no-results")
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Search Term", sortable="term") {{ row.term }}
          DataTableColumn(title="Filters", width="max"): Icon(icon="filter", v-tooltip="row.filters")
          DataTableColumn(title="When", width="max") {{ row.created_at | datetime('medium') }}

    DataTable(title="No Destination Content", endpoint="dashboard/content-with-no-destination")
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Title", sortable="title"): router-link(:to="{name: 'content.edit', params: {id: row.id}}") {{ row.title }}
          DataTableColumn(title="Added", width="max") {{ row.created_at | datetime }}
          DataTableColumn(title="Updated", width="max") {{ row.updated_at | datetime }}
</template>

<script>
import BlockTypeIcon from "@/components/BlockTypeIcon"
import UserList from "@/components/UserList"

export default {
  components: {
    BlockTypeIcon,
    UserList
  },
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-dashboard" } }
  },
  data: () => ({
    title: "Dashboard"
  })
}
</script>

<style>
.page-dashboard {
  .card {
    &-active-users,
    &-new-users {
      .card-body-inner {
        @apply p-8;
      }
    }
  }
}
</style>
