<template lang="pug">
  FormModal(title="New Media Type", endpoint="content-types", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Icon", name="icon", description="Choose an icon from <a href='https://fontawesome.com/icons?d=gallery' target='blank'>FontAwesome</a> and type the icon name without the prepended `fa-`. For example, `fa-book` should be inserted as `book`."): TextInput(v-model="data.icon")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name")
    InputGroup(label="Description", name="description"): TextInput(v-model="data.description")
    InputGroup(label="Lead in", name="lead_in"): TextInput(v-model="data.lead_in")
    InputGroup(label="Call to action", name="call_to_action"): TextInput(v-model="data.call_to_action")
    InputGroup(label="Weight", name="weight"): NumberInput(v-model="data.weight")
</template>

<script>
export default {
  data: () => ({
    data: {
      icon: "",
      name: "",
      description: "",
      lead_in: "",
      call_to_action: "",
      weight: 0
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Media type **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
