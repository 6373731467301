<template lang="pug">
  FormModal(title="Edit Partner Permissions", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Enhanced SEO Features", name="has_premium_seo")
      Radios(v-model="data.has_premium_seo", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Itineraries", name="has_premium_itineraries")
      Radios(v-model="data.has_premium_itineraries", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Lead Generation / Surveys", name="has_premium_forms")
      Radios(v-model="data.has_premium_forms", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Premium Themes", name="has_premium_themes")
      Radios(v-model="data.has_premium_themes", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Travel Advisor Marketing", name="has_premium_share")
      Radios(v-model="data.has_premium_share", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {
      has_premium_forms: null,
      has_premium_itineraries: null,
      has_premium_seo: null,
      has_premium_share: null,
      has_premium_themes: null
    }
  }),
  async mounted() {
    const response = await this.$api.get(this.endpoint)
    const { has_premium_forms, has_premium_itineraries, has_premium_seo, has_premium_share, has_premium_themes } =
      response.data

    this.data.has_premium_forms = has_premium_forms
    this.data.has_premium_itineraries = has_premium_itineraries
    this.data.has_premium_seo = has_premium_seo
    this.data.has_premium_share = has_premium_share
    this.data.has_premium_themes = has_premium_themes

    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Permissions successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
