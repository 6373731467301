<template lang="pug">
  FormModal(title="New Publisher", endpoint="publishers", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", auto-focus)
</template>

<script>
export default {
  data: () => ({
    data: {
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Publisher **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
