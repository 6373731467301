<template lang="pug">
  FormModal(title="Edit Subject Area", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Image", name="featured_image"): FileInput(v-model="data.featured_image")
    InputGroup(label="Subject Area", name="tag"): TextInput(v-model="data.tag", prepend-icon="subjectArea")
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      featured_image: "",
      tag: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Subject area **${this.data.tag}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
