<template>
  <FormModal
    v-if="data"
    title="New Language"
    :endpoint="`partners/${partner_id}/languages`"
    :data="data"
    v-bind="$attrs"
    @success="success"
  >
    <InputGroup label="Language" name="language_id">
      <Select v-model="data.language_id" endpoint="options/languages" />
    </InputGroup>

    <InputGroup
      label="Advisor Email Subject"
      name="advisor_email_subject"
      description="Available variables: %advisorName% %partnerName% %pageTitle% %pageUrl%"
    >
      <TextInput v-model="data.advisor_email_subject" />
    </InputGroup>

    <InputGroup
      label="Advisor Email Message"
      name="advisor_email_body"
      description="Available variables: %advisorName% %partnerName% %pageTitle% %pageUrl%"
    >
      <TextInput v-model="data.advisor_email_body" textarea />
    </InputGroup>

    <InputGroup label="CTA Label" name="cta_label">
      <TextInput v-model="data.cta_label" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    partner_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: {
      language_id: 1,
      advisor_email_subject: null,
      advisor_email_body: null,
      cta_label: null
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Language successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
