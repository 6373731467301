<template lang="pug">
  AppLayout(:title="title")
    DataTable(title="Search History", endpoint="search-history", :filters="filters", :params="params" searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="clearCache()") CLEAR CACHE
      template(v-slot:tabs)
        DataTableTabs(v-model="params.filters.has_results")
          DataTableTab(value="all") All Search Results
          DataTableTab(value="zero") Searches with Zero Results
          DataTableTab(value="has") Searches with Results
      template(v-slot:filters)
        DataTableFilter(v-model="filters.partner_id", endpoint="options/partners", label="Partner", prepend-option="All Partners")
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(icon="clock", title="When", sortable="created_at", width="max") {{ row.created_at | datetime }}
          DataTableColumn(icon="stopwatch", sortable="duration", width="max", :class="durationClass(row.duration)") {{ `${row.duration} ms` }}
          DataTableColumn(icon="disc-drive", sortable="cached", width="max"): Icon(:icon="row.cached ? 'check' : 'times'", :class="`text-${row.cached ? 'success' : 'warning'}`")
          DataTableColumn(title="Search Term", icon="search", sortable="term") {{ row.term }}
          DataTableColumn(title="Results", icon="list-ol", sortable="results", width="max", :class="{'text-danger': !row.results}") {{ row.results | number }}
          DataTableColumn(title="Partner", icon="users", width="max") {{ row.partner && row.partner.name }}
          DataTableColumn(title="User", icon="user", width="max") {{ row.user && row.user.full_name }}
          template(v-slot:actions)
            a(): Icon(icon="chevron-right")
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Search History",
    filters: {},
    params: {
      filters: {
        has_results: "all"
      }
    }
  }),
  methods: {
    async clearCache() {
      if (await this.$modal.confirm("Are you sure you want to clear the applications cache?")) {
        await this.$api.post("tools/clear-cache", { confirm: true })
        this.$notification.success("The application cache has been successfully cleared.")
      }
    },
    durationClass(duration) {
      let className

      if (duration > 1000) {
        className = "text-danger"
      } else if (duration > 500) {
        className = "text-warning"
      }

      return className || "text-success"
    }
  }
}
</script>
