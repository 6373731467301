import Vue from "vue"
import App from "./App"
import router from "./router"
import store from "./store"

import "@/appkit"
import "@/assets/styles/app.css"

/**
 * Layouts
 */
import AppLayout from "@/layouts/App"

Vue.component("AppLayout", AppLayout)

/**
 * AG Shared Assets.
 */
import { MarkdownPlugin, SanitisePlugin } from "@syntax51/ag-shared-assets"
Vue.use(MarkdownPlugin)
Vue.use(SanitisePlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
