<template>
  <FormModal :data="data" :endpoint="endpoint" method="PUT" title="Edit Host Agency" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {
      name: null
    }
  }),
  async created() {
    const { data } = await this.$api.get(this.endpoint)
    this.data.name = data.data.name
    this.loading = false
  },
  methods: {
    created() {},
    success(result) {
      this.$notification.success(`Host agency **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
