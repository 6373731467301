<template lang="pug">
  div.account-types-view-permissions
    Card(
      v-for="(permissions, category, i) in permissions",
      :key="i",
      :title="category",
      no-padding
    )
      div.loading(v-show="loading") Loading...
      div(v-show="!loading")
        Table
          TableRow(
            v-for="(permission, key, i) in permissions",
            :key="i"
          )
            TableColumn(title="Permission") {{ permission }}
            TableColumn(
              v-for="(userTemplate, i) in userTemplates",
              :key="i",
              :title="userTemplate.name",
              width="110px"
            )
              ToggleSwitch(v-model="userTemplate.permissions[key]", @click="savePermissionChange(userTemplate.id, key, !userTemplate.permissions[key])")
</template>

<script>
import { each } from "lodash-es"

export default {
  props: {
    accountType: Object
  },
  data: () => ({
    loading: true,
    permissions: {},
    userTemplates: []
  }),
  async created() {
    await this.fetchPermissions()
    await this.fetchUserTemplates()
    this.loading = false
  },
  methods: {
    async fetchPermissions() {
      let response = await this.$api.get(`account-type-permissions`)
      let permissions = {}

      each(response.data.permissions, (perms, category) => {
        permissions[category] = perms
      })

      this.permissions = permissions
    },
    async fetchUserTemplates() {
      this.userTemplates = await this.$api.values(`account-types/${this.$route.params.id}/user-templates`)
    },
    savePermissionChange(userTemplateId, permissionKey, enabled) {
      this.$api.put(`account-types/${this.$route.params.id}/user-templates/${userTemplateId}/permission`, {
        permission: permissionKey,
        delete: !enabled
      })
    }
  }
}
</script>

<style>
.account-types-view-permissions {
  .loading {
    @apply py-5 px-8;
  }
}
</style>
