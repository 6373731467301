<template lang="pug">
  FormModal(title="New Subject Area", endpoint="tags", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Image", name="image"): FileInput(v-model="data.featured_image")
    InputGroup(label="Subject Area", name="tag"): TextInput(v-model="data.tag", prepend-icon="subjectArea")
</template>

<script>
export default {
  data: () => ({
    data: {
      featured_image: "",
      tag: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Subject area **${this.data.tag}** successfully added.`)
      this.$emit("close", result)
    }
  }
}
</script>
