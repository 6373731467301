<template lang="pug">
  FormModal(:title="title", :endpoint="endpoint", :data="data", v-bind="$attrs", @success="success")
    p.mb-4 Please select the collection you would like to {{action}} {{ content_ids.length }} item(s) to:
    AutoComplete(v-model="data.collection_id", endpoint="search/collections")
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
      validator: value => ["copy", "move"].indexOf(value) !== -1
    },
    collection: {
      type: Object
    },
    content_ids: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      content_ids: []
    }
  }),
  mounted() {
    this.data.content_ids = this.content_ids
  },
  computed: {
    title() {
      return this.action == "copy" ? "Copy to Collection" : "Move to Collection"
    },
    endpoint() {
      return `collections/${this.collection.id}/content/${this.action}`
    },
    pastParticiple() {
      return this.action == "copy" ? "copied" : "moved"
    }
  },
  methods: {
    success(result) {
      this.$notification.success(`The selected content has been successfully ${this.pastParticiple}.`)
      this.$emit("close", result)
    }
  }
}
</script>
