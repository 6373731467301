<template lang="pug">
  AppLayout(v-if="partner.id", :title="partner.name")
    template(v-slot:tabs)
      router-link(:to="{name: 'partners.view.details'}") Details
      router-link(:to="{name: 'partners.view.customizations'}") Customizations
      router-link(:to="{name: 'partners.view.collections'}") Collections
      router-link(:to="{name: 'partners.view.sites'}") Sites
      router-link(:to="{name: 'partners.view.users'}") Users

    template(v-slot:buttons)
      Button(type="danger", prepend-icon="trash", @click="deletePartner()")

    router-view(:partner="partner", @reload="fetchPartner")
</template>

<script>
export default {
  metaInfo() {
    return { title: this.partner.name }
  },
  data: () => ({
    loading: true,
    partner: {
      name: ""
    }
  }),
  created() {
    this.fetchPartner()
  },
  methods: {
    async fetchPartner() {
      this.loading = true
      this.$api.get(`partners/${this.$route.params.id}`).then(response => {
        this.partner = response.data.item
        this.loading = false
      })
    },
    async deletePartner() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.partner.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`partners/${this.partner.id}`)) {
          this.$notification.success(`Partner **${this.partner.name}** successfully deleted.`)
          this.$router.push({ name: "partners" })
        }
      }
    }
  }
}
</script>
