<template lang="pug">
  FormModal(title="New Destination", endpoint="destinations", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Parent Destination", name="parent_id"): Select(v-model="data.parent_id", endpoint="options/destinations", prepend-icon="destination")
    InputGroup(label="Destination", name="name"): TextInput(v-model="data.name", prepend-icon="destination")
</template>

<script>
export default {
  data: () => ({
    data: {
      parent_id: "",
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Destination **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
