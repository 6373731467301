<template>
  <FormModal :data="data" endpoint="host-agencies" title="New Host Agency" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  data: () => ({
    data: {
      name: null
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Host agency **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
