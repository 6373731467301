<template lang="pug">
  AppLayout.page-add-edit-content(:title="title")
    DataTable(ref="dataTable", title="Content", endpoint="content", :filters="filters", :params="params", selectable, searchable)
      template(v-slot:buttons)
        Dropdown.flip-menu(label="New")
          DropdownLink(icon="block-content", :to="{name: 'content.new'}") Content
          DropdownLink(icon="block-image", :to="{name: 'image.new'}") Image
      template(v-slot:tabs)
        DataTableTabs(v-model="params.filters.in_collection")
          DataTableTab(value="all") All Content
          DataTableTab(value="in") In Collection
          //DataTableTab(value="enterprise") In Enterprise Collection
          DataTableTab(value="out") Not In Collection
      template(v-slot:filters)
        DataTableFilter(v-model="filters.block_type", endpoint="options/block-types", label="Block Type", prepend-option="All Block Types")
        DataTableFilter(v-model="filters.destinations", endpoint="options/destinations", label="Destination", prepend-option="All Destinations")
        DataTableFilter(v-model="filters.type_id", endpoint="options/content-types", label="Media Type", prepend-option="All Media Types")
        DataTableFilter(v-model="filters.depth", endpoint="options/content-depths", label="Depth", prepend-option="All Depths")
        DataTableFilter(v-model="filters.tags", endpoint="options/tags", label="Subject Area", prepend-option="All Subject Areas")
        DataTableFilter(v-model="filters.partners", endpoint="options/partners", label="Partner", prepend-option="All Partners")
        DataTableFilter(v-model="filters.libraries", endpoint="options/libraries", label="Library", prepend-option="All Libraries")
        DataTableFilter(v-model="filters.publishers", endpoint="options/publishers", label="Publsher", prepend-option="All Publishers")
        DataTableFilter(v-model="filters.published", endpoint="options/content-published", label="Published", prepend-option="Any")
        DataTableFilter(v-model="filters.admin_id", endpoint="options/content-admins", label="Author", prepend-option="Any Authors")
      template(v-slot:rows="rows")
        DataTableRowContent(v-for="(row, i) in rows", :key="i", :row="row")
      template(v-slot:bulk-actions)
        Button(@click="addToCollection") ADD TO COLLECTION
        Button(@click="addToLibrary") ADD TO LIBRARY
        Button(@click="addToPublisher") ADD TO PUBLISHER
</template>

<script>
import AddContentToCollectionsModal from "@/modals/Content/AddContentToCollectionsModal"
import AddContentToLibrariesModal from "@/modals/Content/AddContentToLibrariesModal"
import AddContentToPublishersModal from "@/modals/Publishers/AddContentToPublishersModal"
import DataTableRowContent from "@/pages/Content/DataTableRowContent"

export default {
  components: {
    DataTableRowContent
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Content",
    filters: {
      block_type: "mixed"
    },
    params: {
      filters: {
        in_collection: "all"
      }
    }
  }),
  methods: {
    newContent() {
      window.alert("newContent")
    },
    deleteSelected() {
      let ids = this.$refs.dataTable.selected() || []
      window.alert(ids.join(", "))
    },
    async addToCollection() {
      await this.$modal.show(AddContentToCollectionsModal, {
        content_ids: this.$refs.dataTable.selected(),
        size: "wide"
      })

      this.$refs.dataTable.reload()
    },
    async addToLibrary() {
      if (
        await this.$modal.show(AddContentToLibrariesModal, {
          content_ids: this.$refs.dataTable.selected(),
          size: "wide"
        })
      ) {
        this.$refs.dataTable.reload()
      }
    },
    async addToPublisher() {
      if (
        await this.$modal.show(AddContentToPublishersModal, {
          content_ids: this.$refs.dataTable.selected(),
          size: "wide"
        })
      ) {
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>

<style>
.page-add-edit-content {
  .card-controls .dropdown {
    @apply inline-flex items-center rounded border border-gray-400 border-primary-default bg-primary-default text-sm font-semibold text-white transition-colors;
    box-shadow: inset 0 -2px rgba(0, 0, 0, 0.1);

    &-trigger {
      @apply flex h-full px-6;

      &:hover {
        @apply border-primary-hover bg-primary-hover;
      }

      &-label:hover {
        @apply text-white;
      }
    }
  }
}
</style>
