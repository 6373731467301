<template lang="pug">
  FormModal(title="Change User Password", method="PUT", :endpoint="`users/${this.user_id}/change-password`", :data="data", v-bind="$attrs", @success="success")
    div.grid.grid-cols-2.gap-x-8
      InputGroup.mb-4(label="Password", name="password"): PasswordInput(v-model="data.password", prepend-icon="lock")
      InputGroup.mb-4(label="Confirm Password", name="password_confirmation"): PasswordInput(v-model="data.password_confirmation", prepend-icon="lock")
    p.text-sm.text-supplement Suggested: #[code.bg-gray-100.mx-1.px-2.py-1.rounded {{ randomPassword }}] #[CopyToClipboardIcon(:content="randomPassword")]
</template>

<script>
import { get } from "lodash-es"

export default {
  props: {
    user_id: Number
  },
  data: () => ({
    user: null,
    data: {
      password: "",
      password_confirmation: ""
    }
  }),
  computed: {
    randomPassword() {
      let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      return [...Array(10)].reduce(a => a + chars[~~(Math.random() * chars.length)], "")
    }
  },
  async created() {
    let response = await this.$api.get(`users/${this.user_id}`)
    this.user = get(response, "data.item", {})
  },
  methods: {
    success(result) {
      this.$notification.success(`Updated **${this.user.full_name}**'s password.`)
      this.$emit("close", result)
    }
  }
}
</script>
