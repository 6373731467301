<template lang="pug">
  FormModal(v-if="data", title="Edit Site", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Main Domain", name="domain")
      TextInput(v-model="data.domain")

    InputGroup(label="Allowed Domains", name="copilot_domains", description="Press 'enter' after each domain. Main domain is included automatically.")
      TagsInput(v-model="data.copilot_domains", prepend-icon="browser", placeholder="Enter domain...")

    InputGroup(label="Image", name="featured_image")
      FileInput(v-model="data.featured_image")

    InputGroup(label="Copilot CSS", name="copilot_css")
      TextInput(v-model="data.copilot_css", textarea)
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: null
  }),
  async mounted() {
    let response = await this.$api.get(this.endpoint)
    this.data = response.data.data
    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Site successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
