<template lang="pug">
  FormModal(title="Edit Content Domain", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Display Name", name="display_name"): TextInput(v-model="data.display_name", placeholder="YouTube", prepend-icon="browser")
    InputGroup(label="Host", name="host"): TextInput(v-model="data.host", placeholder="youtu.be", prepend-icon="mouse")
    InputGroup(label="Media Type", name="mediaType"): Select(v-model="data.content_type_id", endpoint="options/content-types", prepend-icon="media-type", prepend-option="No Media Type")
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      display_name: "",
      host: "",
      content_type_id: null
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Content domain **${this.data.display_name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
