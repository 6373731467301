<template lang="pug">
  Modal(title="Add Selected Content To Libraries", v-bind="$attrs")
    DataTable(ref="dataTable", title="Libraries", endpoint="libraries", :per-page="10", :sort="{name: 'asc'}", searchable)
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Library", sortable="name") {{ row.name }}
          DataTableColumn(title="Partner Access", icon="users")
            span.list-comma-separated(v-for="partner in row.partners") {{ partner.name }}
            span(v-if="!row.partners.length") -
          template(v-slot:actions)
            Button(v-if="added_to.indexOf(row.id) == -1", size="sm", type="primary", @click="addToLibrary(row.id)"): Icon(icon="plus")
            Button(v-else, size="sm", type="default", disabled): Icon.text-success(icon="check")
</template>
<script>
export default {
  props: {
    content_ids: Array
  },
  data: () => ({
    added_to: []
  }),
  methods: {
    async addToLibrary(library_id) {
      this.added_to.push(library_id)
      this.$notification.success(`The selected content has been added to this library.`)
      await this.$api.post(`libraries/${library_id}/content`, { content_ids: this.content_ids })
    }
  }
}
</script>
