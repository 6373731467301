<template lang="pug">
  FormModal(title="Edit Publisher", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", auto-focus)
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Publisher **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
