<template lang="pug">
  AppLayout(v-if="user", title="user.full_name")
    template(#header-content)
      div.flex.items-center.space-x-3
        Avatar(:label="user.initials", :image-url="user.personal_photo && user.personal_photo.thumb_url", size="12")
        h1.page-header-title {{ user.full_name }}

    template(v-slot:tabs)
      router-link(:to="{name: 'users.view.details'}") Details
      router-link(v-if="user.collections_count", :to="{name: 'users.view.collections'}") {{ user.collections_count }} {{ user.collections_count === 1 ?  "Collection" : "Collections" }}
      router-link(v-if="user.shared_collections_count", :to="{name: 'users.view.sharedCollections'}") {{ user.shared_collections_count }} {{ user.shared_collections_count === 1 ?  "Shared Collection" : "Shared Collections" }}

    template(v-slot:buttons)
      Button(prepend-icon="magic", @click="impersonateUser()", v-tooltip="'Impersonate User'")
      Button(prepend-icon="lock", @click="changeUserPassword()", v-tooltip="'Change Password'")
      Button(type="danger", prepend-icon="trash", @click="deleteUser()", v-tooltip="'Delete User'")

    router-view(:user="user", :shared-collections-by-partner="shared_collections_by_partner", @reload="fetchUser", @updatedUser="updateUser")
</template>

<script>
import Avatar from "@/components/Avatar.vue"
import ChangeUserPasswordModal from "@/modals/Users/ChangeUserPasswordModal"

export default {
  metaInfo() {
    return { title: this.user?.full_name }
  },
  components: {
    Avatar
  },
  data: () => ({
    loading: true,
    user: null,
    shared_collections_by_partner: null
  }),
  async created() {
    await this.fetchUser()
  },
  methods: {
    async fetchUser() {
      this.loading = true
      const response = await this.$api.get(`users/${this.$route.params.id}`)
      this.user = response.data.data
      this.shared_collections_by_partner = response.data.shared_collections_by_partner
      this.loading = false
    },
    async impersonateUser() {
      let response = await this.$api.get(`users/${this.user.id}/impersonate`)
      let token = response && response.data && response.data.token
      window.open(`${process.env.VUE_APP_PARTNER_URL}impersonate/${token}`, "_blank")
    },
    async changeUserPassword() {
      if (await this.$modal.show(ChangeUserPasswordModal, { user_id: this.user.id })) {
        this.fetchUser()
      }
    },
    updateUser(updatedUser) {
      this.user = updatedUser
    },
    async deleteUser() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.user.full_name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`users/${this.user.id}`)) {
          this.$notification.success(`User **${this.user.full_name}** successfully deleted.`)
          this.$router.push({ name: "users" })
        }
      }
    }
  }
}
</script>
