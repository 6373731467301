import Vue from "vue"
import Vuex from "vuex"

import { AuthStoreModule } from "@syntax51/app-kit"
import SidebarStoreModule from "@/store/SidebarStoreModule"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: AuthStoreModule(),
    sidebar: SidebarStoreModule
  }
})
