<template>
  <div v-if="site">
    <Card title="Site" :noPadding="true">
      <template v-slot:buttons>
        <Button prepend-icon="pencil" @click="editSite()" />
      </template>

      <dl class="dl-horizontal">
        <dt>Main Domain</dt>
        <dd>{{ site.domain }}</dd>
        <dt>Allowed Domains</dt>
        <dd>
          <ul v-if="site.copilot_domains" class="list-comma-separated">
            <li v-for="(domain, index) in site.copilot_domains" :key="index">{{ domain }}</li>
          </ul>
          <span v-else class="text-supplement">-</span>
        </dd>
        <dt>Featured Image</dt>
        <dd>
          <Icon
            :icon="site.featured_image ? 'check' : 'times'"
            :class="site.featured_image ? 'text-success' : 'text-supplement'"
          />
        </dd>
        <dt>Copilot CSS</dt>
        <dd>
          <Icon
            :icon="site.copilot_css ? 'check' : 'times'"
            :class="site.copilot_css ? 'text-success' : 'text-supplement'"
          />
        </dd>
      </dl>
    </Card>

    <DataTable
      ref="transformationsDataTable"
      title="JavaScript Transformations"
      :endpoint="`partners/${partner.id}/sites/${site.id}/transformations`"
    >
      <template v-slot:buttons>
        <Button type="primary" @click="newPartnerSiteTransformation()">NEW TRANSFORMATION</Button>
      </template>

      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Name" sortable="name">{{ row.name }}</DataTableColumn>
          <DataTableColumn title="Language">{{
            (row.language && row.language.name) || "All Languages"
          }}</DataTableColumn>
          <DataTableColumn title="Href">
            <Icon
              :icon="!row.advisor_cta && row.href ? 'check' : 'times'"
              :class="!row.advisor_cta && row.href ? 'text-success' : 'text-supplement'"
            />
          </DataTableColumn>
          <DataTableColumn title="Text">
            <Icon :icon="row.text ? 'check' : 'times'" :class="row.text ? 'text-success' : 'text-supplement'" />
          </DataTableColumn>
          <DataTableColumn title="Advisor CTA" sortable="advisor_cta">
            <Icon
              :icon="row.advisor_cta ? 'check' : 'times'"
              :class="row.advisor_cta ? 'text-success' : 'text-supplement'"
            />
          </DataTableColumn>
          <DataTableColumn title="Replace" sortable="replace_element">
            <Icon
              :icon="row.replace_element ? 'check' : 'times'"
              :class="row.replace_element ? 'text-success' : 'text-supplement'"
            />
          </DataTableColumn>
          <DataTableColumn title="Enabled" sortable="enabled">
            <Icon :icon="row.enabled ? 'check' : 'times'" :class="row.enabled ? 'text-success' : 'text-supplement'" />
          </DataTableColumn>
          <template v-slot:actions>
            <Dropdown icon="ellipsis-v" class="flip-menu">
              <DropdownLink icon="pencil" @click="editPartnerSiteTransformation(row.id)">Edit</DropdownLink>
              <DropdownLink icon="trash" @click="deletePartnerSiteTransformation(row)">Delete</DropdownLink>
            </Dropdown>
          </template>
        </DataTableRow>
      </template>
    </DataTable>

    <DataTable
      ref="redirectsDataTable"
      title="Redirects"
      :endpoint="`partners/${partner.id}/sites/${site.id}/redirects`"
    >
      <template v-slot:buttons>
        <Button type="primary" @click="newPartnerSiteRedirect()">NEW REDIRECT</Button>
      </template>

      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Redirect" sortable="path_pattern">{{ row.path_pattern }}</DataTableColumn>
          <DataTableColumn title="To" sortable="redirect_url">{{ row.redirect_url }}</DataTableColumn>
          <DataTableColumn title="Exact Match" sortable="use_exact_match">
            <Icon
              :icon="row.use_exact_match ? 'check' : 'times'"
              :class="row.use_exact_match ? 'text-success' : 'text-supplement'"
            />
          </DataTableColumn>
          <DataTableColumn title="Ignore Previous Page" sortable="ignore_previous_page">
            <Icon
              :icon="row.ignore_previous_page ? 'check' : 'times'"
              :class="row.ignore_previous_page ? 'text-success' : 'text-supplement'"
            />
          </DataTableColumn>
          <DataTableColumn title="Enabled" sortable="enabled">
            <Icon :icon="row.enabled ? 'check' : 'times'" :class="row.enabled ? 'text-success' : 'text-supplement'" />
          </DataTableColumn>
          <template v-slot:actions>
            <Dropdown icon="ellipsis-v" class="flip-menu">
              <DropdownLink icon="pencil" @click="editPartnerSiteRedirect(row.id)">Edit</DropdownLink>
              <DropdownLink icon="trash" @click="deletePartnerSiteRedirect(row)">Delete</DropdownLink>
            </Dropdown>
          </template>
        </DataTableRow>
      </template>
    </DataTable>

    <p class="mt-6 text-right">
      <button @click="deleteSite">Delete Site</button>
    </p>
  </div>
</template>

<script>
import EditPartnerSiteModal from "@/modals/Partners/EditPartnerSiteModal.vue"
import NewPartnerSiteTransformationModal from "@/modals/Partners/NewPartnerSiteTransformationModal.vue"
import EditPartnerSiteTransformationModal from "@/modals/Partners/EditPartnerSiteTransformationModal.vue"
import NewPartnerSiteRedirectModal from "@/modals/Partners/NewPartnerSiteRedirectModal.vue"
import EditPartnerSiteRedirectModal from "@/modals/Partners/EditPartnerSiteRedirectModal.vue"

export default {
  metaInfo() {
    return { title: `${this.partner.name} Site` }
  },
  props: {
    partner: null
  },
  data: () => ({
    site: null
  }),
  async created() {
    let response = await this.$api.get(`partners/${this.partner.id}/sites/${this.$route.params.site_id}`)
    this.site = response.data.data
  },
  methods: {
    async editSite() {
      const response = await this.$modal.show(EditPartnerSiteModal, {
        endpoint: `partners/${this.partner.id}/sites/${this.site.id}`
      })

      if (response) {
        this.site = response.data
      }
    },
    async deleteSite() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.site.domain}**? This is **irreversible and will permanently delete** this site and all associated data such as transformations.`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`partners/${this.partner.id}/sites/${this.site.id}`)) {
          this.$notification.success(`Site **${this.site.domain}** successfully deleted.`)
          this.$router.push({ name: "partners.view.sites", params: { id: this.partner.id } })
        }
      }
    },
    async newPartnerSiteRedirect() {
      if (
        await this.$modal.show(NewPartnerSiteRedirectModal, {
          partner_id: this.partner.id,
          site_id: this.site.id
        })
      ) {
        this.$refs.redirectsDataTable.reload()
      }
    },
    async editPartnerSiteRedirect(id) {
      if (
        await this.$modal.show(EditPartnerSiteRedirectModal, {
          partner_id: this.partner.id,
          site_id: this.site.id,
          redirect_id: id
        })
      ) {
        this.$refs.redirectsDataTable.reload()
      }
    },
    async deletePartnerSiteRedirect(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete this redirect?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`partners/${this.partner.id}/sites/${this.site.id}/redirects/${row.id}`)) {
          this.$notification.success(`Redirect successfully deleted.`)
          this.$refs.redirectsDataTable.reload()
        }
      }
    },
    async newPartnerSiteTransformation() {
      if (
        await this.$modal.show(NewPartnerSiteTransformationModal, {
          partner_id: this.partner.id,
          site_id: this.site.id
        })
      ) {
        this.$refs.transformationsDataTable.reload()
      }
    },
    async editPartnerSiteTransformation(id) {
      if (
        await this.$modal.show(EditPartnerSiteTransformationModal, {
          partner_id: this.partner.id,
          site_id: this.site.id,
          transformation_id: id
        })
      ) {
        this.$refs.transformationsDataTable.reload()
      }
    },
    async deletePartnerSiteTransformation(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`partners/${this.partner.id}/sites/${this.site.id}/transformations/${row.id}`)) {
          this.$notification.success(`**${row.name}** successfully deleted.`)
          this.$refs.transformationsDataTable.reload()
        }
      }
    }
  }
}
</script>
