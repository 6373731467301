<template lang="pug">
  Modal.view-api-log(title="View API Log", v-bind="$attrs")
    dl.dl-horizontal
      dt Partner
      dd {{ log.partner && log.partner.name }}
      dt Path / Endpoint
      dd {{ log.path }}
      dt Parameters
      dd {{ log.parameters }}
      dt IP Address
      dd.text-monospaced {{ log.ip_address }}
      dt User Agent (Browser)
      dd.text-monospaced {{ log.user_agent }}
      dt Request At
      dd {{ log.created_at | datetime('long') }}
</template>

<script>
export default {
  props: {
    log: Object
  }
}
</script>

<style>
.modal.view-api-log {
  .modal-body {
    @apply mt-12 p-0;
  }

  dl {
    @apply border-t;
  }
}
</style>
