<template>
  <FormModal title="Edit Region" :endpoint="endpoint" method="PUT" :data="data" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" placeholder="United States" />
    </InputGroup>

    <InputGroup name="country_ids" label="Countrie(s)">
      <MultiSelect
        v-model="data.country_ids"
        placeholder="Add countries to this region"
        endpoint="search/countries"
        searchable
      />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {
      name: null,
      country_ids: []
    }
  }),
  async created() {
    const { data } = await this.$api.get(this.endpoint)
    this.data.name = data.data.name
    this.data.country_ids = data.data.countries.map(country => country.id)

    this.loading = false
  },
  methods: {
    created() {},
    success(result) {
      this.$notification.success(`Region **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
