<template lang="pug">
  AppLayout(v-if="collection.id", :title="collection.name")
    template(v-slot:buttons)
      Button(type="primary", prepend-icon="pencil", @click="editCollection()")
      Button(prepend-icon="clone", @click="duplicateCollection()")
      Button(type="danger", prepend-icon="trash", @click="deleteCollection()")

    Row(lg="row")
      Column(lg="2/3")
        Card(title="Collection", :noPadding="true")
          dl.dl-horizontal
            dt Name
            dd(v-html="$markdown(collection.name)")
            dt Type
            dd {{ collection.type | ucwords }}
            dt Language
            dd {{ collection.language.name | ucwords }}
            dt Owner
            dd
              router-link.flex.items-center(:to="{name: ownerRouteName, params: {id: collection.owner.id}}")
                span.inline-flex.justify-center.mr-2.text-supplement.w-6: Icon(:icon="ownerIcon")
                span {{ collection.owner.name }}

        DataTable(v-if="endpoint", ref="dataTable", title="Content", :endpoint="endpoint", :sort="{title: 'asc'}", selectable, searchable)
          template(v-slot:buttons)
            Button(type="primary", @click="addContentToCollection()") ADD CONTENT
          template(v-slot:rows="rows")
            DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
              DataTableColumn(title="Type", sortable="block_type", width="min"): BlockTypeIcon(:type="row.block_type")
              DataTableColumn(title="Title", sortable="title"): router-link(:to="{name: 'content.edit', params: {id: row.id}}") {{ row.title }}
              DataTableColumn(title="Added", sortable="created_at", width="max") {{ row.added_at | datetime }}
          template(v-slot:bulk-actions)
            Button(@click="moveOrCopySelected('move')") MOVE SELECTED
            Button(@click="moveOrCopySelected('copy')") COPY SELECTED
            Button(@click="removeSelected()") REMOVE SELECTED

      Column(lg="1/3")
        Card(title="Landing Page", :noPadding="true")
          div.divide-y
            a(
              :href="collection.landing_page_url"
              target="_blank"
              class="flex px-8 py-4 space-x-3 hover:bg-gray-100"
            )
              span.flex-shrink-0: Icon(icon="browser")
              span View Live
            a(
              :href="`${collection.landing_page_url}?${collection.type === 'partner' ? 'pid' : 'uid'}=${collection.owner.alt_id}`"
              target="_blank"
              class="flex px-8 py-4 rounded-b space-x-3 hover:bg-gray-100",
            )
              span.flex-shrink-0: Icon(icon="browser")
              span View Live (with contact bar)
            a(
              :href="collection.landing_page_url"
              target="_blank"
              class="flex px-8 py-4 space-x-3 hover:bg-gray-100"
            )
              span.flex-shrink-0: Icon(icon="palette")
              span Admin Design

        Card(title="Meta", :noPadding="true")
          dl.dl-horizontal
            dt ID
            dd.text-monospaced {{ collection.id }}
            dt Alternative ID
            dd.text-monospaced {{ collection.alt_id }} #[CopyToClipboardIcon(:content="collection.alt_id")]
            dt Created
            dd {{ collection.created_at | datetime }}
            dt Updated
            dd {{ collection.updated_at | datetime }}

        Card(title="Inspires", :noPadding="true")
          dl.dl-horizontal
            dt Invite Link
            dd
              a(:href="collection.invite_url", target="_blank") View Invite
              CopyToClipboardIcon.ml-3(:content="collection.invite_url")
            dt Invite Impressions
            dd -
            dt Invite Acceptances
            dd {{ collection.shared_collections_count }}
            dt Public
            dd(:class="{'text-success': collection.inspires_publish_at}"): Icon(:icon="collection.inspires_publish_at ? 'check' : 'times'")
            dt Featured
            dd(:class="{'text-success': collection.inspires_feature_at}"): Icon(:icon="collection.inspires_feature_at ? 'check' : 'times'")
</template>

<script>
import EditCollectionModal from "@/modals/Collections/EditCollectionModal"
import DuplicateCollectionModal from "@/modals/Collections/DuplicateCollectionModal"
import AddContentToCollection from "@/modals/Collections/AddContentToCollectionModal"
import BlockTypeIcon from "../../components/BlockTypeIcon.vue"
import CopyOrMoveContentToCollectionModal from "@/modals/Collections/CopyOrMoveContentToCollectionModal"

export default {
  components: {
    BlockTypeIcon
  },
  metaInfo() {
    return { title: this.collection.name }
  },
  data: () => ({
    loading: true,
    collection: {}
  }),
  computed: {
    endpoint() {
      return this.collection.id && `collections/${this.collection.id}/content`
    },
    ownerIcon() {
      return this.collection.type === "partner" ? "users" : "user"
    },
    ownerRouteName() {
      return this.collection.type === "partner" ? "partners.view" : "users.view"
    }
  },
  async created() {
    await this.fetchCollection()
  },
  watch: {
    $route: "fetchCollection"
  },
  methods: {
    async fetchCollection() {
      this.loading = true
      let response = await this.$api.get(`collections/${this.$route.params.id}`)
      this.collection = response.data.item
      this.loading = false
    },
    async editCollection() {
      let response = await this.$modal.show(EditCollectionModal, { collection_id: this.collection.id })
      if (response) this.collection = response.item
    },
    async duplicateCollection() {
      let response = await this.$modal.show(DuplicateCollectionModal, { existingCollectionId: this.collection.id })
      if (response) this.$router.push({ name: "collections.view", params: { id: response.item.id } })
    },
    async deleteCollection() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.collection.name}**?`,
          buttonText: "Delete"
        })
      ) {
        let response = await this.$api.delete(`collections/${this.collection.id}`)

        if (response) {
          this.$notification.success(`Collection **${this.collection.name}** successfully deleted.`)
          this.$router.push({ name: "collections" })
        }
      }
    },
    async addContentToCollection() {
      await this.$modal.show(AddContentToCollection, {
        collection: this.collection,
        size: "wide"
      })
      this.$refs.dataTable.reload()
    },
    async moveOrCopySelected(action) {
      let result = await this.$modal.show(CopyOrMoveContentToCollectionModal, {
        action,
        collection: this.collection,
        content_ids: this.$refs.dataTable.selected()
      })
      if (result) this.$refs.dataTable.reload()
    },
    async removeSelected() {
      let content_ids = this.$refs.dataTable.selected()
      let confirm = await this.$modal.confirm({
        type: "danger",
        message: `Are you sure you would like to remove the **${content_ids.length}** content item(s) from this collection?`,
        buttonText: "Remove"
      })
      if (confirm) {
        await this.$api.post(`${this.endpoint}/remove`, { content_ids })
        this.$notification.success(`${content_ids.length} item(s) successfully removed from this collection.`)
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>
