<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Collections", endpoint="collections", :filters="filters", :params="params", :sort-by="{'created_at': 'desc'}", searchable, selectable)
      template(v-slot:buttons)
        Button(type="primary", @click="newCollection()") NEW COLLECTION
      template(v-slot:tabs)
        DataTableTabs(v-model="params.filters.type")
          DataTableTab(value="all") All Collections
          DataTableTab(value="partner") Partner
          DataTableTab(value="personal") Personal
      template(v-slot:filters)
        DataTableFilter(v-model="filters.partner", endpoint="options/partners", label="Partner", prepend-option="All Partners")
        DataTableFilter(v-model="filters.inspires", endpoint="options/inspires", label="Inspires", prepend-option="All Collections")
        //DataTableFilter(v-model="filters.account_type", endpoint="options/account-types", label="Account Type", prepend-option="All Account Types")
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Collection", icon="collection", sortable="name")
            router-link(:to="{name: 'collections.view', params: {id: row.id}}") {{ row.name }}
            div.mt-2.text-sm.text-supplement(v-if="row.folder") #[Icon.mr-1(icon="folder")] {{ row.folder && row.folder.label }}
          DataTableColumn(title="Owner")
            div.flex.items-center
              span.inline-flex.justify-center.mr-3.text-supplement.w-6: Icon(:icon="row.type === 'partner' ? 'users' : 'user'")
              span {{ row.owner.name }}
          DataTableColumn(icon="content", sortable="contents_count", sort-order="desc", width="max") {{ row.contents_count | number | default('0') }}
          DataTableColumn(title="Impressions", width="max"): span.text-supplement -
          DataTableColumn(title="Acceptances", sortable="shared_collections_count", sort-order="desc", width="max") {{ row.shared_collections_count }}
          DataTableColumn(title="Inspires", width="max")
            div.flex.items-center.space-x-3.text-supplement
              div.flex.justify-center.w-6
                Icon.text-success.text-lg(v-if="row.inspires_feature_at", icon="check")
                span.text-gray-400(v-else) -
              span.text-sm Featured
            div.flex.items-center.space-x-3.text-supplement
              div.flex.justify-center.w-6
                Icon.text-success.text-lg(v-if="row.inspires_publish_at", icon="check")
                span.text-gray-400(v-else) -
              span.text-sm Public
          template(v-slot:actions)
            a(v-if="row.landing_page_url", :href="row.landing_page_url", target="_blank", class="text-supplement hover:text-primary"): Icon(icon="browser")
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="browser", :to="{name: 'collections.view', params: {id: row.id}}") View
              DropdownLink(icon="pencil", @click="editCollection(row)") Edit
              DropdownLink(icon="clone", @click="duplicateCollection(row.id)") Duplicate
              DropdownHorizontalRule(v-if="row.landing_page_url")
              DropdownLink(v-if="row.landing_page_url", icon="eye", :href="row.landing_page_url", target="_blank") View Live
              DropdownLink(v-if="row.landing_page_url", icon="eye", :href="`${row.landing_page_url}?${row.type === 'partner' ? 'pid' : 'uid'}=${row.owner.alt_id}`", target="_blank") View Live#[br]#[span.text-supplement with contact bar]
              DropdownHorizontalRule
              DropdownLink(icon="eye", :href="row.invite_url", target="_blank") View Invite
              DropdownHorizontalRule
              DropdownLink(icon="trash", @click="deleteCollection(row)") Delete
      template(v-slot:bulk-actions)
        Button(type="primary", prepend-icon="earth-america", @click="publishOnInspires") Publish on Inspires
        Button(type="danger", prepend-icon="earth-america", @click="unpublishOnInspires") Unpublish on Inspires
        Button(type="primary", prepend-icon="star", @click="featureOnInspires") Feature on Inspires
        Button(type="danger", prepend-icon="star", @click="removeFeaturedOnInspires") Remove featured on Inspires
</template>

<script>
import NewCollectionModal from "@/modals/Collections/NewCollectionModal"
import EditCollectionModal from "@/modals/Collections/EditCollectionModal"
import DuplicateCollectionModal from "@/modals/Collections/DuplicateCollectionModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Collections",
    filters: {},
    params: {
      filters: {
        type: "all"
      }
    }
  }),
  methods: {
    async newCollection() {
      if (await this.$modal.show(NewCollectionModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editCollection(collection) {
      let response = await this.$modal.show(EditCollectionModal, { collection_id: collection.id })
      if (response) this.$refs.dataTable.reload()
    },
    async duplicateCollection(id) {
      let response = await this.$modal.show(DuplicateCollectionModal, { existingCollectionId: id })
      if (response) this.$router.push({ name: "collections.view", params: { id: response.item.id } })
    },
    async deleteCollection(collection) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${collection.name}**?`,
          buttonText: "Delete"
        })
      ) {
        let response = await this.$api.delete(`collections/${collection.id}`)

        if (response) {
          this.$notification.success(`Collection **${collection.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    },
    onCopy() {
      this.$notification.success("Copied!")
    },
    async publishOnInspires() {
      const response = await this.$api.post(`collections/publish-on-inspires`, {
        ids: this.$refs.dataTable.selected()
      })

      if (response) {
        this.$refs.dataTable.deselectAll()
        this.$refs.dataTable.reload()
        this.$notification.success(`Collections successfully marked public on inspires.`)
      }
    },
    async unpublishOnInspires() {
      const response = await this.$api.post(`collections/unpublish-on-inspires`, {
        ids: this.$refs.dataTable.selected()
      })

      if (response) {
        this.$refs.dataTable.deselectAll()
        this.$refs.dataTable.reload()
        this.$notification.success(`Collections successfully removed from public on inspires.`)
      }
    },
    async featureOnInspires() {
      const response = await this.$api.post(`collections/feature-on-inspires`, {
        ids: this.$refs.dataTable.selected()
      })

      if (response) {
        this.$refs.dataTable.deselectAll()
        this.$refs.dataTable.reload()
        this.$notification.success(`Collections successfully marked featured on inspires.`)
      }
    },
    async removeFeaturedOnInspires() {
      const response = await this.$api.post(`collections/remove-featured-on-inspires`, {
        ids: this.$refs.dataTable.selected()
      })

      if (response) {
        this.$refs.dataTable.deselectAll()
        this.$refs.dataTable.reload()
        this.$notification.success(`Collections successfully removed from featured on inspires.`)
      }
    }
  }
}
</script>
