<template lang="pug">
  AppLayout(:title="title")
    DataTable(title="API Logs", endpoint="api-logs", :filters="filters", searchable)
      template(v-slot:filters)
        DataTableFilter(v-model="filters.partner_id", endpoint="options/partners", label="Partner", prepend-option="All Partners")
        DataTableFilter(v-model="filters.success", endpoint="options/api-requests", label="Status", prepend-option="All Requests")
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(icon="clock", title="When", sortable="created_at", width="max") {{ row.created_at | datetime }}
          DataTableColumn(icon="stopwatch", sortable="duration", width="max", :class="durationClass(row.duration)") {{ `${row.duration} ms` }}
          DataTableColumn(title="Status", icon="code-branch", sortable="status_code", width="max", :class="statusCodeClass[row.status_code] || 'text-success'") {{ statusCodeLabel[row.status_code] || row.status_code }}
          DataTableColumn(title="Endpoint", icon="server", sortable="path") {{ row.path }}
          DataTableColumn(title="Results", icon="list-ol", sortable="results", width="max") {{ row.results | number }}
          DataTableColumn(title="Parameters", icon="filter", width="max") {{ objectSize(row.parameters) | number }}
          DataTableColumn(title="Partner", icon="users", :class="{'text-danger': !row.partner}") {{ row.partner && row.partner.name | default('Not logged in') }}
          template(v-slot:actions): a(@click="view(row)"): Icon(icon="search")
</template>

<script>
import { size } from "lodash-es"
import ViewApiLogModal from "@/modals/ApiLogs/ViewApiLogModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Api Logs",
    filters: {},
    statusCodeLabel: {
      500: "500 - API Error",
      405: "405 - Not Allowed",
      404: "404 - Not Found",
      401: "401 - Forbidden"
    },
    statusCodeClass: {
      500: "text-danger",
      405: "text-danger",
      404: "text-warning",
      401: "text-danger"
    }
  }),
  methods: {
    durationClass(duration) {
      let className

      if (duration > 1000) {
        className = "text-danger"
      } else if (duration > 500) {
        className = "text-warning"
      }

      return className || "text-success"
    },
    objectSize(parameters) {
      return size(parameters)
    },
    view(log) {
      this.$modal.show(ViewApiLogModal, { log })
    }
  }
}
</script>
