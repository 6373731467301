/**
 * Calculate the percentage difference between two numbers.
 *
 * @param {number} oldValue - The old value.
 * @param {number} newValue - The new value.
 * @returns {number} - The percentage difference rounded to two decimal places.
 */
const percentageDifference = (oldValue, newValue) => {
  if (oldValue === 0) {
    return 0
  }

  const percentageDifference = ((newValue - oldValue) / Math.abs(oldValue)) * 100
  return Math.round(percentageDifference * 100) / 100
}

/**
 * Utility function 'pick' iterates over an input object and returns a new object
 * that includes a subset of its properties specified by the 'keysToKeep' array.
 *
 * @param {Object} obj - The input object to filter keys from.
 * @param {Array} keysToKeep - An array that specifies the keys to be kept in the returned object.
 * @returns {Object} - An object containing only the properties specified in the 'keysToKeep' array.
 * @throws {TypeError} - If 'obj' is not an object or 'keysToKeep' is not an array.
 */
function pick(obj, keysToKeep) {
  // Type check parameters
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    throw new TypeError("First argument must be an object.")
  }

  if (!Array.isArray(keysToKeep)) {
    throw new TypeError("Second argument must be an array of keys to keep.")
  }

  // Perform picking operation
  return Object.entries(obj)
    .filter(([key]) => keysToKeep.includes(key))
    .reduce((resultObj, [key, value]) => {
      resultObj[key] = value
      return resultObj
    }, {})
}

export { percentageDifference, pick }
