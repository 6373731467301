<template>
  <dl class="divide-y divide-gray-200">
    <slot />
  </dl>
</template>

<script>
export default {
  props: {
    widget: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      widget: this.widget
    }
  }
}
</script>
