import { render, staticRenderFns } from "./NewPartnerModal.vue?vue&type=template&id=4bb28895&lang=pug&"
import script from "./NewPartnerModal.vue?vue&type=script&lang=js&"
export * from "./NewPartnerModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports