<template>
  <FormModal title="New Language" endpoint="languages" :data="data" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" placeholder="English" />
    </InputGroup>

    <InputGroup label="Alpha2" name="alpha2">
      <TextInput v-model="data.alpha2" placeholder="us" />
    </InputGroup>

    <InputGroup label="User Profile Enabled" name="user_profile_enabled">
      <ToggleSwitch v-model="data.user_profile_enabled" label="Allow users to select this language in their profile" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  data: () => ({
    data: {
      alpha2: null,
      name: null,
      user_profile_enabled: false
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Language **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
