<template lang="pug">
  FormModal(v-if="data", title="New Site", :endpoint="`partners/${partner_id}/sites`", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Main Domain", name="domain")
      TextInput(v-model="data.domain")

    InputGroup(label="Allowed Domains", name="copilot_domains", description="Press 'enter' after each domain. Main domain is included automatically.")
      TagsInput(v-model="data.copilot_domains", prepend-icon="browser", placeholder="Enter domain...")

    InputGroup(label="Image", name="featured_image")
      FileInput(v-model="data.featured_image")

    InputGroup(label="Copilot CSS", name="copilot_css")
      TextInput(v-model="data.copilot_css", textarea)
</template>

<script>
export default {
  props: {
    partner_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: {
      domain: null,
      copilot_domains: [],
      featured_image: null,
      copilot_css: null
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Site **${this.data.domain}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
