var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex items-center justify-center rounded-full text-xs font-semibold text-white transition-colors",class:{
    'bg-gray-200': (_vm.imageUrl || !_vm.label) && !_vm.backgroundColor,
    'bg-primary-default': (_vm.imageUrl || _vm.label) && !_vm.backgroundColor,
    'h-10 w-10': _vm.size === '10',
    'h-12 w-12': _vm.size === '12'
  },style:({
    'background-color': _vm.backgroundColor
  })},[(_vm.imageUrl)?_c('img',{staticClass:"rounded-full",class:{
      'h-10 w-10': _vm.size === '10',
      'h-12 w-12': _vm.size === '12'
    },attrs:{"src":_vm.imageUrl}}):(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.status)?_c('span',{staticClass:"absolute left-0 top-0 -ml-1 -mt-1 h-4 w-4 rounded-full border-2 border-white",class:_vm.status === 'idle' ? 'bg-orange-400' : 'bg-green-400'}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }