<template lang="pug">
  FormModal(title="Edit Facade", :endpoint="endpoint", method="PUT" :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Facade", name="term"): TextInput(v-model="data.term", prepend-icon="facade")
    InputGroup(label="Destination", name="destination_id"): Select(v-model="data.destination_id", endpoint="options/destinations", prepend-icon="destination")
    InputGroup(label="Type", name="type")
      Radios(v-model="data.type", inline)
        Radio(value="correction") Correction
        Radio(value="related") Related
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      term: "",
      destination_id: "",
      type: "correction"
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Facade **${this.data.term}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
