<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Publishers", endpoint="publishers", :filters="filters", :sort="{ name: 'asc' }", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newPublisher()") NEW PUBLISHER
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Name", sortable="name")
            a(@click="editPublisher(row.id)") {{ row.name }}
          DataTableColumn(icon="content", sortable="content_count", sort-order="desc", width="max") {{ row.content_count | number | default('0') }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editPublisher(row.id)") Edit
              DropdownLink(icon="trash", @click="deletePublisher(row)") Delete
</template>

<script>
import NewPublisherModal from "@/modals/Publishers/NewPublisherModal"
import EditPublisherModal from "@/modals/Publishers/EditPublisherModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Publishers",
    filters: {}
  }),
  methods: {
    async newPublisher() {
      if (await this.$modal.show(NewPublisherModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editPublisher(id) {
      if (await this.$modal.show(EditPublisherModal, { endpoint: `publishers/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deletePublisher(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`publishers/${row.id}`)) {
          this.$notification.success(`Publisher **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
