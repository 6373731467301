<template lang="pug">
  AppLayout(:title="accountType.name || ''")

    template(v-slot:tabs)
      router-link(:to="{name: 'account-types.view.permissions'}") Permissions
      router-link(:to="{name: 'account-types.view.user-templates'}") User Templates

    template(v-slot:buttons)
      Button(type="primary", prepend-icon="pencil", @click="editAccountType()")

    router-view
</template>

<script>
import EditAccountTypeModal from "@/modals/AccountTypes/EditAccountTypeModal"

export default {
  metaInfo() {
    return { title: this.accountType.name }
  },
  data: () => ({
    loading: true,
    accountType: {}
  }),
  async created() {
    let response = await this.$api.get(`account-types/${this.$route.params.id}`)
    this.accountType = response.data.item
    this.loading = false
  },
  methods: {
    async editAccountType() {
      let response = await this.$modal.show(EditAccountTypeModal, { endpoint: `account-types/${this.accountType.id}` })
      if (response) this.accountType = response.item
    }
  }
}
</script>
