<template lang="pug">
InputGroup.markdown-input(:name="name", :label="label", :optional="optional")
  TextInput(textarea, @input="updateValue", :value="value", v-bind="$attrs")
  div.flex.items-center.justify-between
    Alert(v-show="lengthFeedback.status", :type="lengthFeedback.status", :content="lengthFeedback.content")
    Dropdown.formatting-help.flip-menu.mt-4.ml-auto(label="Formatting help")
      div.divide-y
        div.bg-gray-100.font-medium.py-4.px-6.rounded-t This field can be formatted with Markdown. Here are the basics.
        div.py-4.px-6.select-text
          div.font-bold Bold
          div Markdown: Be **bold**
          div Result: Be #[strong bold]
        div.py-4.px-6.select-text
          div.font-bold Italics
          div Markdown: I haven't been everywhere, *but it's on my list.*
          div Result: I haven't been everywhere, #[em but it's on my list.]
        div.py-4.px-6.select-text
          div.font-bold Links
          div Markdown: To be inspired, [click here](http://apr.gd/joy).
          div Result: To be inspired, #[a(href="http://apr.gd/joy", target="_blank") click here]
        div.py-4.px-6.select-text
          div.font-bold Paragraphs
          div Hit Return twice after a sentence...#[br]#[br]..to start a new paragraph.
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    suggestedMinLength: {
      type: [Number, null],
      default: null
    },
    suggestedMaxLength: {
      type: [Number, null],
      default: null
    },
    optional: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lengthFeedback() {
      const feedback = {
        status: null,
        content: null,
        length: this.value && this.value.length
      }

      const { length } = feedback
      const characterWord = length === 1 ? "character" : "characters"

      if (this.suggestedMinLength && this.suggestedMaxLength) {
        if (!length || length <= this.suggestedMinLength) {
          feedback.status = "info"
          feedback.content = `We recommend ${this.suggestedMinLength}-${this.suggestedMaxLength} characters.`
        } else if (length >= this.suggestedMaxLength) {
          feedback.status = "warning"
          feedback.content = `**${length}** ${characterWord}. We recommend fewer than ${this.suggestedMaxLength}.`
        } else {
          feedback.status = "success"
          feedback.content = `${length} ${characterWord}`
        }
      } else if (this.suggestedMinLength) {
        if (!length || length <= this.suggestedMinLength) {
          feedback.status = "info"
          feedback.content = `We recommend more than ${this.suggestedMinLength} characters.`
        } else {
          feedback.status = "success"
          feedback.content = `${length} ${characterWord}`
        }
      } else if (this.suggestedMaxLength) {
        if (!length) {
          feedback.status = "info"
          feedback.content = `We recommend fewer than ${this.suggestedMaxLength} characters.`
        } else if (length >= this.suggestedMaxLength) {
          feedback.status = "warning"
          feedback.content = `**${length}** ${characterWord}. We recommend fewer than ${this.suggestedMaxLength}.`
        } else {
          feedback.status = "success"
          feedback.content = `${length} ${characterWord}`
        }
      }

      return feedback
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value)
    }
  }
}
</script>

<style>
.markdown-input {
  .alert {
    @apply mt-4 border-none p-0;

    &-icon {
      @apply mr-3 h-6 w-8 rounded-full text-xs;
      min-width: auto;
    }
  }

  .formatting-help .dropdown-menu {
    @apply max-w-full py-0;
    min-width: 250px;

    @screen md {
      min-width: 400px;
    }
  }
}
</style>
