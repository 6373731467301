<template lang="pug">
  FormModal(title="Edit Account Type", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Account Type", name="name"): TextInput(v-model="data.name", placeholder="Enterprise Edition", prepend-icon="user-circle")
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Account type **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
