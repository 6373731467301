<template lang="pug">
 DataTable(ref="dataTable", title="User Templates", :endpoint="`account-types/${this.$route.params.id}/user-templates`")
  template(v-slot:buttons)
    Button(type="primary", @click="newUserTemplate()") NEW USER TEMPLATE
  template(v-slot:rows="rows")
    DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
      DataTableColumn(title="User Template", sortable="name") {{ row.name }}
      template(v-slot:actions)
        Dropdown.flip-menu(icon="ellipsis-v")
          DropdownLink(icon="pencil", @click="editUserTemplate(row.id)") Edit
          DropdownLink(icon="trash", @click="deleteUserTemplate(row)") Delete
</template>

<script>
import NewUserTemplateModal from "@/modals/AccountTypes/UserTemplates/NewUserTemplateModal"
import EditUserTemplateModal from "@/modals/AccountTypes/UserTemplates/EditUserTemplateModal"

export default {
  methods: {
    async newUserTemplate() {
      if (
        await this.$modal.show(NewUserTemplateModal, {
          endpoint: `account-types/${this.$route.params.id}/user-templates`
        })
      ) {
        this.$refs.dataTable.reload()
      }
    },
    async editUserTemplate(id) {
      if (
        await this.$modal.show(EditUserTemplateModal, {
          endpoint: `account-types/${this.$route.params.id}/user-templates/${id}`
        })
      ) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteUserTemplate(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`account-types/${this.$route.params.id}/user-templates/${row.id}`)) {
          this.$notification.success(`User template **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
