<template>
  <Row lg="row">
    <Column lg="2/3">
      <DataTable
        ref="dataTable"
        :endpoint="'users/' + user.id + '/shared-collections'"
        :params="params"
        :sort-by="{ created_at: 'desc' }"
        searchable
        title="Shared Collections"
      >
        <template v-slot:rows="rows">
          <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
            <DataTableColumn icon="collection" title="Shared Collection">
              <router-link
                :to="{ name: 'shared-collections.view', params: { id: row.id } }"
                class="font-medium"
                v-html="highlight(row.collection.name)"
              ></router-link>
              <div class="mt-2 flex space-x-6 text-sm">
                <router-link
                  :to="{ name: 'partners.view', params: { id: row.collection.partner.id } }"
                  class="text-supplement"
                >
                  <Icon class="mr-2" icon="users"></Icon>
                  <span v-html="highlight(row.collection.partner.name)"></span>
                </router-link>
              </div>
            </DataTableColumn>
            <DataTableColumn sortable="has_personal_message" title="Personal Message">
              <BooleanIndicator v-model="row.has_personal_message"></BooleanIndicator>
            </DataTableColumn>
            <DataTableColumn
              :class="{ 'text-danger font-medium': !row.personalisations_count }"
              sortable="personalisations_count"
              title="Personalizations"
            >
              {{ row.personalisations_count }}
            </DataTableColumn>
            <DataTableColumn icon="clock" sortable="created_at" title="Accepted">
              {{ row.created_at_diff }}
            </DataTableColumn>
          </DataTableRow>
        </template>
      </DataTable>
    </Column>

    <Column lg="1/3">
      <Card no-padding title="Associated Partners">
        <div class="datatable">
          <table class="datatable-table" style="grid-template-columns: auto auto">
            <thead>
              <tr>
                <th class="datatable__heading">Partner</th>
                <th class="datatable__heading justify-end">Count</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in sharedCollectionsByPartner" :key="index" class="datatable__row">
                <td class="datatable__column">{{ row.partner_name }}</td>
                <td class="datatable__column justify-end">{{ row.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </Column>
  </Row>
</template>

<script>
import BooleanIndicator from "@/components/BooleanIndicator"

export default {
  components: {
    BooleanIndicator
  },
  metaInfo() {
    return { title: `${this.user.full_name} Shared Collections` }
  },
  props: {
    user: {},
    sharedCollectionsByPartner: null
  },
  data: () => ({
    title: "Shared Collections",
    params: {
      filters: {
        query: ""
      }
    }
  }),
  methods: {
    highlight(value) {
      return this.$options.filters.highlight(value, this.params.filters.query)
    }
  }
}
</script>
