<template>
  <div :class="widget ? 'md:grid-cols-3' : 'md:grid-cols-5'" class="md:grid">
    <dt class="inline-flex items-center py-4 pl-8 pr-4 text-sm font-medium text-supplement">
      <slot name="label">{{ label }}</slot>
    </dt>
    <dd :class="widget ? 'md:col-span-2' : 'md:col-span-4'" class="inline-flex items-center py-4 pr-8 pl-4">
      <slot />
    </dd>
  </div>
</template>

<script>
export default {
  inject: ["widget"],
  props: {
    label: String
  }
}
</script>
