<template lang="pug">
  FormModal(title="New Account Type", endpoint="account-types", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Account Type", name="name"): TextInput(v-model="data.name", placeholder="Enterprise Edition", prepend-icon="user-circle")
</template>

<script>
export default {
  data: () => ({
    data: {
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Account type **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
