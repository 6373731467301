<template lang="pug">
  FormModal(title="Edit Partner", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Account Type", name="account_type_id"): Select(v-model="data.account_type_id", endpoint="options/account-types", prepend-icon="user-circle")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name", prepend-icon="user")
    InputGroup(label="Slug", name="slug", description="Remember: changing the slug may require redirects")
      TextInput(v-model="data.slug", prepend-icon="link")
    InputGroup(label="Email", name="email"): TextInput(v-model="data.email", prepend-icon="envelope", placeholder="name@site.com")
    InputGroup(label="Phone", name="phone"): TextInput(v-model="data.phone", prepend-icon="phone", placeholder="+country code + number")
    InputGroup(label="URL", name="url"): TextInput(v-model="data.url", prepend-icon="mouse", placeholder="www.site.com")
    InputGroup(label="Whitelist Domains", name="whitelist_domains", description="Press 'enter' after each domain")
      TagsInput(v-model="data.whitelist_domains", prepend-icon="browser", placeholder="Enter domain...")
    InputGroup(label="Same Tab Domains", name="same_tab_domains", description="Press 'enter' after each domain")
      TagsInput(v-model="data.same_tab_domains", prepend-icon="browser", placeholder="Enter domain...")
    InputGroup(label="Landing Page Domain", name="landing_page_domain"): TextInput(v-model="data.landing_page_domain", prepend-icon="browser")
</template>

<script>
import { get, pick } from "lodash-es"

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {}
  }),
  async mounted() {
    let response = await this.$api.get(this.endpoint)
    this.data = pick(get(response, "data.item", {}), [
      "id",
      "account_type_id",
      "name",
      "slug",
      "email",
      "phone",
      "url",
      "whitelist_domains",
      "same_tab_domains",
      "landing_page_domain",
      "copilot_domains"
    ])
    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Partner **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
