<template>
  <AppLayout v-if="sharedCollection.id" :title="sharedCollection.collection.name">
    <template v-slot:buttons>
      <Button type="danger" prepend-icon="trash" @click="deleteSharedCollection()" />
    </template>

    <Row lg="row">
      <Column lg="2/3">
        <Card title="Extended Content Experience" :noPadding="true">
          <dl class="dl-horizontal">
            <dt>ID</dt>
            <dd>{{ sharedCollection.hashid }}</dd>
            <dt>User</dt>
            <dd>
              <router-link :to="{ name: 'users.view', params: { id: sharedCollection.user.id } }">{{
                sharedCollection.user.full_name || sharedCollection.user.email.toLowerCase()
              }}</router-link>
            </dd>
            <dt>Accepted</dt>
            <dd>{{ sharedCollection.created_at | datetime }}</dd>
          </dl>
        </Card>

        <DataTable
          title="Personalizations"
          :endpoint="`shared-collections/${sharedCollection.id}/personalisations`"
          :sort="{ created_at: 'desc' }"
          :params="params"
          searchable
        >
          <template v-slot:rows="rows">
            <DataTableRow v-for="personalisation in rows" :key="personalisation.id" :row="row">
              <DataTableColumn title="Personalization / Personal Message">
                <a
                  :href="personalisation.landing_page_url"
                  target="_blank"
                  class="font-medium"
                  v-html="highlight(personalisation.name)"
                />
                <div class="mt-0.5 text-sm" :class="{ 'text-supplement': !personalisation.personal_message }">
                  {{ personalisation.personal_message || "-" }}
                </div>
              </DataTableColumn>
              <DataTableColumn title="CTA" :class="{ 'text-supplement': personalisation.cta_display === 'none' }">
                <Icon v-if="personalisation.cta_display !== 'none'" icon="check" class="text-success" />
                <span v-else>-</span>
              </DataTableColumn>
              <DataTableColumn
                title="Customisations"
                :class="{ 'text-supplement': !personalisation.content_customisations_count }"
              >
                <Icon v-if="personalisation.content_customisations_count" icon="check" class="text-success" />
                <span v-else>-</span>
              </DataTableColumn>
            </DataTableRow>
          </template>
        </DataTable>

        <div class="divide-y">
          <div v-for="personalisation in sharedCollection.personalisations" :key="personalisation.id" class="px-8 py-6">
            <dl class="dl-horizontal">
              <dt>Name</dt>
              <dd>
                <a :href="personalisation.landing_page_url" target="_blank">{{ personalisation.name }}</a>
              </dd>
              <dt>Personal Message</dt>
              <dd :class="{ 'text-supplement': !personalisation.personal_message }">
                {{ personalisation.personal_message || "-" }}
              </dd>
              <dt>CTA</dt>
              <dd :class="{ 'text-supplement': personalisation.cta_display === 'none' }">
                <Icon v-if="personalisation.cta_display !== 'none'" icon="check" />
                <span v-else>-</span>
              </dd>
            </dl>
          </div>
        </div>
      </Column>

      <Column lg="1/3">
        <Card title="Base Collection" :noPadding="true">
          <dl class="dl-horizontal">
            <dt>Name</dt>
            <dd>
              <router-link :to="{ name: 'collections.view', params: { id: sharedCollection.collection.id } }">
                <Icon icon="collection" class="mr-3" />
                <span v-html="$markdown(sharedCollection.collection.name)" />
              </router-link>
            </dd>
            <dt>Partner</dt>
            <dd>
              <router-link :to="{ name: 'partners.view', params: { id: sharedCollection.collection.partner.id } }">
                <Icon icon="users" class="mr-3" />
                <span>{{ sharedCollection.collection.partner.name }}</span>
              </router-link>
            </dd>
          </dl>
        </Card>
      </Column>
    </Row>
  </AppLayout>
</template>

<script>
export default {
  metaInfo() {
    return { title: this.sharedCollection.collection?.name }
  },
  data: () => ({
    sharedCollection: {},
    params: {
      filters: {
        query: ""
      }
    }
  }),
  async created() {
    await this.fetchCollection()
  },
  watch: {
    $route: "fetchCollection"
  },
  methods: {
    async fetchCollection() {
      let response = await this.$api.get(`shared-collections/${this.$route.params.id}`)
      this.sharedCollection = response.data.item
    },
    highlight(value) {
      return this.$options.filters.highlight(value, this.params.filters.query)
    },
    async deleteSharedCollection() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.sharedCollection.collection.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`shared-collections/${this.sharedCollection.id}`)) {
          this.$notification.success(
            `Shared collection **${this.sharedCollection.collection.name}** successfully deleted.`
          )
          this.$router.push({ name: "shared-collections" })
        }
      }
    }
  }
}
</script>
