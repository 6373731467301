<template>
  <AppLayout :title="title">
    <DataTable ref="dataTable" title="Countries" endpoint="countries" :sort="{ name: 'asc' }" searchable>
      <template v-slot:buttons>
        <Button type="primary" @click="newCountry()">NEW COUNTRY</Button>
      </template>
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Country" sortable="name">
            <router-link :to="{ name: 'countries.view', params: { id: row.id } }">{{ row.name }}</router-link>
          </DataTableColumn>
          <DataTableColumn title="Alpha2" sortable="alpha2" width="max" :class="{ 'text-supplement': !row.alpha2 }">{{
            row.alpha2 || "-"
          }}</DataTableColumn>
          <DataTableColumn title="Alpha3" sortable="alpha3" width="max" :class="{ 'text-supplement': !row.alpha3 }">{{
            row.alpha3 || "-"
          }}</DataTableColumn>
          <DataTableColumn
            title="Country Regions"
            sortable="country_regions_count"
            width="max"
            :class="{ 'text-supplement': !row.country_regions_count }"
          >
            {{ row.country_regions_count || "-" }}
          </DataTableColumn>
          <template v-slot:actions>
            <Dropdown icon="ellipsis-v" class="flip-menu">
              <DropdownLink icon="pencil" @click="editCountry(row.id)">Edit</DropdownLink>
              <DropdownLink icon="trash" @click="deleteCountry(row)">Delete</DropdownLink>
            </Dropdown>
          </template>
        </DataTableRow>
      </template>
    </DataTable>
  </AppLayout>
</template>

<script>
import NewCountryModal from "@/modals/Countries/NewCountryModal"
import EditCountryModal from "@/modals/Countries/EditCountryModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Countries"
  }),
  methods: {
    async newCountry() {
      if (await this.$modal.show(NewCountryModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editCountry(id) {
      if (await this.$modal.show(EditCountryModal, { endpoint: `countries/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteCountry(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`countries/${row.id}`)) {
          this.$notification.success(`Country **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>

<style>
.page-countries-index {
  .language {
    @apply flex items-center;
  }
}
</style>
