<template lang="pug">
  header.app-header
    div.app-header-logo(:class="{'is-compact': sidebar === 'compact'}"): Logo
    a(@click="toggleSidebar", class="block p-6 text-supplement hover:text-paragraph"): Icon(icon="bars")
    //- Search(prepend-icon="search", placeholder="Search")
    div.app-header-controls
      //- Dropdown.app-header-controls-item.app-header-controls-notifications.flip-menu(id="notifications-dropdown")
        template(v-slot:label): span.app-header-controls-notifications-content: Icon(icon="bell")
        DropdownLink Notification 1
        DropdownLink Notification 2
        DropdownLink Notification 3
        DropdownLink Notification 4
        DropdownLink Notification 5
        DropdownHorizontalRule
        DropdownLink View All
      Dropdown.app-header-controls-item.app-header-controls-avatar.flip-menu(id="user-dropdown")
        template(v-slot:label): span.app-header-controls-avatar-content {{ user.initials || '??' }}
        DropdownLink(icon="user") Profile
        DropdownLink(icon="cog") Settings
        DropdownHorizontalRule
        DropdownLink(icon="door-open", :to="{name: 'auth.logout'}") Logout
</template>

<script>
import { mapGetters } from "vuex"
import Logo from "@/components/Logo"
import { Search } from "@syntax51/app-kit"

export default {
  components: {
    Logo,
    Search
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      sidebar: "sidebar/style"
    })
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("sidebar/toggle")
    }
  }
}
</script>

<style>
.app-header {
  @apply fixed left-0 right-0 top-0 z-50 flex items-center bg-white shadow;
  height: theme("header.height");

  &-logo {
    @apply flex select-none items-center px-8 text-lg font-semibold text-paragraph;
    height: theme("header.height");
    width: theme("sidebar.width");

    .icon {
      @apply mr-3 text-primary-default;
    }

    &.is-compact {
      @apply px-0;
    }
  }

  .search .form-control {
    @apply border-none bg-transparent py-0 pr-0;
    height: 40px;
    line-height: 40px;
  }

  &-controls {
    @apply ml-auto flex items-center px-2;

    &-item {
      @apply mx-2;
    }

    &-notifications {
      .dropdown-trigger-icon {
        @apply hidden;
      }

      &-content {
        @apply flex items-center justify-center font-semibold transition-colors;
        height: 36px;
        width: 36px;
      }
    }

    &-avatar {
      .dropdown-trigger-icon {
        @apply ml-2;
      }

      &-content {
        @apply flex items-center justify-center rounded-full bg-primary-default text-xs font-semibold text-white transition-colors;
        height: 36px;
        width: 36px;
      }

      &:hover {
        .app-header-controls-avatar-content {
          @apply bg-primary-600;
        }
      }
    }

    .dropdown-menu {
      top: 51px;
    }
  }
}
</style>
