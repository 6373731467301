<template>
  <AppLayout :title="title">
    <DataTable ref="dataTable" :sort="{ name: 'asc' }" :title="title" endpoint="consortia" searchable>
      <template v-slot:buttons>
        <Button type="primary" @click="newConsortium()">NEW CONSORTIUM</Button>
      </template>
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn sortable="name" title="Name">{{ row.name }}</DataTableColumn>
          <template v-slot:actions>
            <Dropdown class="flip-menu" icon="ellipsis-v">
              <DropdownLink icon="pencil" @click="editConsortium(row.id)">Edit</DropdownLink>
              <DropdownLink icon="trash" @click="deleteConsortium(row)">Delete</DropdownLink>
            </Dropdown>
          </template>
        </DataTableRow>
      </template>
    </DataTable>
  </AppLayout>
</template>

<script>
import NewConsortiumModal from "@/modals/Consortium/NewConsortiumModal"
import EditConsortiumModal from "@/modals/Consortium/EditConsortiumModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Consortia"
  }),
  methods: {
    async newConsortium() {
      if (await this.$modal.show(NewConsortiumModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editConsortium(id) {
      if (await this.$modal.show(EditConsortiumModal, { endpoint: `consortia/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteConsortium(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`consortia/${row.id}`)) {
          this.$notification.success(`Consortium **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
