<template lang="pug">
  Modal(title="Add Selected Content To Collections", v-bind="$attrs")
    DataTable(ref="dataTable", title="Collections", endpoint="collections", :per-page="10", :sort="{created_at: 'desc'}", searchable)
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Collection", sortable="name") {{ row.name }}
          DataTableColumn(title="Owner")
            div.flex.items-center(v-if="row.owner")
              span.inline-flex.justify-center.mr-3.text-supplement.w-6: Icon(:icon="row.type === 'partner' ? 'users' : 'user'")
              span {{ row.owner.name }}
            span.text-supplement(v-else) -
          DataTableColumn(title="Added", sortable="created_at", width="max") {{ row.created_at | datetime }}
          template(v-slot:actions)
            Button(v-if="added_to.indexOf(row.id) == -1", size="sm", type="primary", @click="addToCollection(row.id)"): Icon(icon="plus")
            Button(v-else, size="sm", type="default", disabled): Icon.text-success(icon="check")
</template>
<script>
export default {
  props: {
    content_ids: Array
  },
  data: () => ({
    added_to: []
  }),
  methods: {
    async addToCollection(collection_id) {
      this.added_to.push(collection_id)
      this.$notification.success(`The selected content has been added to this collection.`)
      await this.$api.post(`collections/${collection_id}/content`, { content_ids: this.content_ids })
    }
  }
}
</script>
