<template lang="pug">
  FormModal(title="Edit Content Depth", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Icon", name="icon", description="Choose an icon from <a href='https://fontawesome.com/icons?d=gallery' target='blank'>FontAwesome</a> and type the icon name without the prepended `fa-`. For example, `fa-book` should be inserted as `book`."): TextInput(v-model="data.icon")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name")
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      icon: "",
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Content depth **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
