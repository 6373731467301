<template>
  <Row lg="row">
    <Column lg="2/3">
      <!-- 'landing_page_email_template' => $this->landing_page_email_template,-->
      <!-- 'hostAgency' => new HostAgencyResource($this->whenLoaded('hostAgency')),-->
      <!-- 'host_agency_other' => $this->host_agency_other,-->
      <!-- 'consortium' => new ConsortiumResource($this->whenLoaded('consortium')),-->
      <!-- 'lead_generation_notifications' => $this->lead_generation_notifications,-->

      <Card no-padding title="Details">
        <template v-slot:buttons>
          <Button prepend-icon="pencil" @click="editUser('details', 'Update User Details')" />
        </template>

        <DefinitionList>
          <DefinitionListItem label="Name">{{ user.full_name }}</DefinitionListItem>
          <DefinitionListItem label="Email">
            {{ user.email }}
            <Badge :type="user.email_verified_at ? 'success' : 'info'" class="ml-3 text-xs">
              {{ user.email_verified_at ? "VERIFIED" : "UNVERIFIED" }}
            </Badge>
          </DefinitionListItem>
          <DefinitionListItem label="Phone">{{ user.phone }}</DefinitionListItem>
          <DefinitionListItem label="Company">{{ user.company_name }}</DefinitionListItem>
          <DefinitionListItem label="Languages">
            {{ user.languages | commaSeparated }}
            <Badge v-if="user.languages.length && user.using_suggested_languages" class="ml-3 text-xs" type="info">
              SUGGESTED
            </Badge>
          </DefinitionListItem>
          <DefinitionListItem label="Country">
            {{ user.country && user.country.name }}
            <Badge v-if="user.country && user.using_suggested_country" class="ml-3 text-xs" type="info">
              SUGGESTED
            </Badge>
          </DefinitionListItem>
          <DefinitionListItem label="State">
            {{ user.country_state && user.country_state.name }}
            <Badge
              v-if="user.country_state"
              :type="user.using_suggested_country_state ? 'success' : 'info'"
              class="ml-3 text-xs"
            >
              landing_page_domain
              {{ user.using_suggested_country_state ? "VERIFIED" : "UNVERIFIED" }}
            </Badge>
          </DefinitionListItem>
          <DefinitionListItem label="Host Agency">{{ user.hostAgency && user.hostAgency.name }}</DefinitionListItem>
          <DefinitionListItem label="Consortium">{{ user.consortium && user.consortium.name }}</DefinitionListItem>
        </DefinitionList>
      </Card>

      <Card no-padding title="Media">
        <template v-slot:buttons>
          <Button prepend-icon="pencil" @click="editUser('media', 'Update User Media')" />
        </template>

        <DefinitionList>
          <DefinitionListItem label="Logo">
            <img v-if="user.logo && user.logo.thumb_url" :src="user.logo.thumb_url" class="max-h-12" />
            <span v-else class="text-supplement">-</span>
          </DefinitionListItem>
          <DefinitionListItem label="Light Logo">
            <img
              v-if="user.logo_light && user.logo_light.thumb_url"
              :src="user.logo_light.thumb_url"
              class="max-h-12"
            />
            <span v-else class="text-supplement">-</span>
          </DefinitionListItem>
          <DefinitionListItem label="Header Image">
            <img
              v-if="user.header_background_image && user.header_background_image.thumb_url"
              :src="user.header_background_image.thumb_url"
              class="max-h-12"
            />
            <span v-else class="text-supplement">-</span>
          </DefinitionListItem>
          <DefinitionListItem label="Personal Photo">
            <Avatar
              v-if="user.personal_photo && user.personal_photo.thumb_url"
              :image-url="user.personal_photo.thumb_url"
              size="12"
            />
            <span v-else class="text-supplement">-</span>
          </DefinitionListItem>
        </DefinitionList>
      </Card>

      <Card v-if="user.partner" no-padding title="Explore">
        <template v-slot:buttons>
          <Button prepend-icon="pencil" @click="editUser('explore')" />
        </template>

        <DefinitionList>
          <DefinitionListItem label="UID">
            {{ user.alt_id }}
            <CopyToClipboardIcon :content="user.alt_id" class="ml-2" />
          </DefinitionListItem>
          <DefinitionListItem label="Landing Page Sharing">
            <span v-if="user.display_contact_info_on_links == 'personal'">Include my personal contact information</span>
            <span v-else-if="user.display_contact_info_on_links == 'company'">Include company contact information</span>
            <span v-else-if="user.display_contact_info_on_links == 'none'">Don't include any contact information</span>
          </DefinitionListItem>
          <DefinitionListItem label="Live Editor Display">
            <span v-if="user.live_editor_display === 'embed'">Embed</span>
            <span v-else-if="user.live_editor_display === 'landing-page'">Landing Page</span>
          </DefinitionListItem>
          <DefinitionListItem label="Lead Notifications">
            <Icon :icon="user.lead_generation_notifications ? 'check' : 'times'" />
          </DefinitionListItem>
        </DefinitionList>
      </Card>

      <Card no-padding title="Travel Advisor">
        <template v-slot:buttons>
          <Button prepend-icon="pencil" @click="editUser('travelAdvisor')" />
        </template>

        <DefinitionList v-if="user.is_travel_advisor">
          <DefinitionListItem label="Booking Email">{{ user.booking_email }}</DefinitionListItem>
          <DefinitionListItem label="Booking Phone Number">{{ user.booking_phone_number }}</DefinitionListItem>
          <DefinitionListItem label="Booking IATA">{{ user.booking_iata }}</DefinitionListItem>
          <DefinitionListItem label="Booking CLIA">{{ user.booking_clia }}</DefinitionListItem>
        </DefinitionList>

        <DefinitionList v-else>
          <DefinitionListItem label="Travel Advisor">User is not a travel advisor</DefinitionListItem>
        </DefinitionList>
      </Card>

      <Card no-padding title="Copilot">
        <DefinitionList>
          <DefinitionListItem label="Insider Param">
            {{ user.insider_param }}
            <CopyToClipboardIcon :content="user.insider_param" class="ml-2" />
          </DefinitionListItem>
        </DefinitionList>
      </Card>
    </Column>

    <Column lg="1/3">
      <Card
        v-if="user.partner || user.signup_source || user.signup_partner || user.signup_collection"
        no-padding
        title="Account"
      >
        <DefinitionList v-if="user.partner" widget>
          <DefinitionListItem label="Partner">
            <router-link v-if="user.partner" :to="{ name: 'partners.view', params: { id: user.partner.id } }">
              {{ user.partner.name }}
            </router-link>
          </DefinitionListItem>
          <DefinitionListItem label="Type">
            <router-link
              v-if="user.partner"
              :to="{ name: 'account-types.view.permissions', params: { id: user.partner.account_type.id } }"
            >
              {{ user.partner && user.partner.account_type && user.partner.account_type.name }}
            </router-link>
          </DefinitionListItem>
          <DefinitionListItem label="Role">
            <router-link
              v-if="user.partner"
              :to="{ name: 'account-types.view.user-templates', params: { id: user.partner.account_type.id } }"
            >
              {{ user.user_template && user.user_template.name }}
            </router-link>
          </DefinitionListItem>
          <DefinitionListItem label="Internal">
            <Icon :icon="user.internal ? 'check' : 'times'" />
          </DefinitionListItem>
        </DefinitionList>

        <DefinitionList v-else-if="user.signup_source || user.signup_partner || user.signup_collection" widget>
          <DefinitionListItem v-if="user.signup_source" label="Signup Source">
            {{ user.signup_source && user.signup_source.replace("-", " ") | ucwords }}
          </DefinitionListItem>
          <DefinitionListItem v-if="user.signup_partner" label="Signup Partner">
            <router-link :to="{ name: 'partners.view', params: { id: user.signup_partner.id } }">
              {{ user.signup_partner.name }}
            </router-link>
          </DefinitionListItem>
          <DefinitionListItem v-if="user.signup_collection" label="Signup Collection">
            <router-link :to="{ name: 'collections.view', params: { id: user.signup_collection.id } }">
              {{ user.signup_collection.name }}
            </router-link>
          </DefinitionListItem>
        </DefinitionList>
      </Card>

      <Card no-padding title="Profile Status">
        <template #buttons>
          <div class="mt-4 mr-6 flex items-center space-x-6">
            <div class="text-sm text-supplement">{{ `${user.profile_completion_percentage}%` }}</div>
            <div class="relative h-2 w-10 overflow-hidden rounded-full">
              <div class="absolute inset-0 bg-gray-200" />
              <div
                class="absolute inset-y-0 left-0 bg-pink-600"
                :style="{ width: `${user.profile_completion_percentage}%` }"
              />
            </div>
          </div>
        </template>

        <div class="space-y-1.5 p-8">
          <div v-for="(completed, task) in user.profile_tasks" :key="task" class="flex items-center space-x-4">
            <div class="size-4 grid shrink-0 place-items-center">
              <Icon v-if="completed" icon="check" class="text-green-600" />
              <div v-else class="h-3.5 w-3.5 rounded border" />
            </div>
            <div>{{ task }}</div>
          </div>
        </div>
      </Card>

      <Card no-padding title="Meta">
        <DefinitionList widget>
          <DefinitionListItem label="ID">{{ user.id }}</DefinitionListItem>
          <DefinitionListItem label="Last Active">{{ user.active_at | datetime }}</DefinitionListItem>
          <DefinitionListItem label="Created">{{ user.created_at | datetime }}</DefinitionListItem>
          <DefinitionListItem label="Updated">{{ user.updated_at | datetime }}</DefinitionListItem>
        </DefinitionList>
      </Card>
    </Column>
  </Row>
</template>

<script>
import { Badge } from "@syntax51/app-kit"
import DefinitionList from "@/components/DefinitionList.vue"
import DefinitionListItem from "@/components/DefinitionListItem.vue"
import EditUserModal from "@/modals/Users/EditUserModal.vue"
import Avatar from "@/components/Avatar.vue"

export default {
  components: {
    Avatar,
    DefinitionListItem,
    DefinitionList,
    Badge
  },
  props: {
    user: {}
  },
  metaInfo() {
    return { title: `${this.user.full_name} Details` }
  },
  methods: {
    async editUser(form, title = null) {
      const updatedUser = await this.$modal.show(EditUserModal, { endpoint: `users/${this.user.id}`, form, title })

      if (updatedUser) {
        this.$emit("updatedUser", updatedUser)
      }
    }
  }
}
</script>
