<template lang="pug">
  FormModal(title="Edit Inspires", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Logo URL", name="inspires_logo_url")
      TextInput(v-model="data.inspires_logo_url", disabled)
    MarkdownInput(v-model="data.inspires_partner_details", label="Brand Intro", name="inspires_partner_details")
    InputGroup(label="Call to Actions", name="inspires_allow_ctas")
      ToggleSwitch(v-model="data.inspires_allow_ctas", label="Allow advisors to use call to actions")
</template>

<script>
import { get, pick } from "lodash-es"
import MarkdownInput from "@/components/MarkdownInput.vue"

export default {
  components: {
    MarkdownInput
  },
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {}
  }),
  async mounted() {
    let response = await this.$api.get(this.endpoint)
    this.data = pick(get(response, "data", {}), [
      "inspires_logo_url",
      "inspires_partner_details",
      "inspires_allow_ctas"
    ])
    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Partner **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
