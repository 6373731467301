<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Content Domains", endpoint="content-domains", :sort="{display_name: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newContentDomain()") NEW CONTENT DOMAIN
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Content Domain", icon="browser", sortable="display_name")
            p.content-domain-name {{ row.display_name }}
            p {{ row.host }}
          DataTableColumn(title="Media Type", icon="media-type", sortable="display_name")
            div.media-type(v-if="row.media_type")
              Icon.media-type__icon(:icon="row.media_type.icon")
              span.media-type__la2bel {{ row.media_type.name }}
            div.text-supplement(v-if="!row.media_type") -
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editContentDomain(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteContentDomain(row)") Delete
</template>

<script>
import NewContentDomainModal from "@/modals/ContentDomains/NewContentDomainModal"
import EditContentDomainModal from "@/modals/ContentDomains/EditContentDomainModal"
import { MediaIcon } from "@syntax51/app-kit"

export default {
  components: {
    MediaIcon
  },
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-content-domains" } }
  },
  data: () => ({
    title: "Content Domains"
  }),
  methods: {
    async newContentDomain() {
      if (await this.$modal.show(NewContentDomainModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editContentDomain(id) {
      if (await this.$modal.show(EditContentDomainModal, { endpoint: `content-domains/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteContentDomain(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.display_name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`content-domains/${row.id}`)) {
          this.$notification.success(`Content domain **${row.display_name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>

<style>
.page-content-domains {
  .content-domain-name {
    @apply font-semibold text-heading;
  }

  .media-type {
    @apply flex items-center;

    &__icon {
      @apply mr-3 w-5 text-primary-default;
    }

    &__label {
      @apply text-sm;
    }
  }
}
</style>
