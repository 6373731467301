<template>
  <Card>
    <p class="text-xs font-medium uppercase tracking-wider">{{ label }}</p>
    <p class="mt-3 text-4xl font-semibold text-heading">{{ count | number }}</p>
    <p class="mt-4 text-sm text-supplement">
      <span
        :class="{
          'text-success': difference > 0,
          'text-danger': difference < 0
        }"
        class="text-sm font-bold"
      >
        <Icon :icon="difference > 0 ? 'long-arrow-up' : 'long-arrow-down'" class="mr-2" prefix="fas" />
        {{ absoluteDifference }}%&nbsp;
      </span>
      <span>{{ differenceLabel }}</span>
    </p>
  </Card>
</template>

<script lang="ts">
import { percentageDifference } from "@/utils"

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    previousCount: {
      type: Number,
      required: true
    },
    differenceLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    difference() {
      return percentageDifference(this.previousCount, this.count)
    },
    absoluteDifference() {
      return Math.abs(this.difference).toFixed(2)
    }
  }
}
</script>
