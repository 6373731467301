<template lang="pug">
  FormModal(title="Edit AI", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Brand Voice", name="ai_brand_voice")
      TextInput(v-model="data.ai_brand_voice", textarea)
</template>

<script>
import { get, pick } from "lodash-es"

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {}
  }),
  async mounted() {
    let response = await this.$api.get(this.endpoint)
    this.data = pick(get(response, "data", {}), ["ai_brand_voice"])
    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Partner **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
