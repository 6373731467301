<template lang="pug">
  Modal.modal--reorder(title="Reorder Content Depths", v-bind="$attrs")
    draggable.items(v-model="contentDepths", @end="onEnd", ghost-class="ghost")
      div.item(v-for="(contentDepth, index) in contentDepths", :key="contentDepth.id")
        div.item__content
          span.content-depth__icon: Icon(:icon="contentDepth.icon")
          span {{ contentDepth.name }}
        div.item__handle: Icon(icon="arrows")
</template>

<script>
import draggable from "vuedraggable"

export default {
  components: {
    draggable
  },
  data: () => ({
    contentDepths: []
  }),
  async mounted() {
    this.contentDepths = await this.$api.values("content-depths/reorder")
  },
  methods: {
    async onEnd(evt) {
      if (evt.oldIndex === evt.newIndex) return

      let order = []

      this.contentDepths.forEach((contentDepth, index) => {
        order.push({ id: contentDepth.id, order: index + 1 })
      })

      await this.$api.put(`content-depths/reorder`, { order })
      this.$notification.success(`Content depths order has been updated.`)
    }
  }
}
</script>

<style>
.modal--reorder {
  @apply overflow-hidden;

  .content-depth__icon {
    @apply mr-4 w-6 flex-shrink-0 text-center text-lg text-primary-default;
  }

  .modal-header {
    @apply py-10;
  }

  &.has-title .modal-body {
    @apply bg-gray-200 pt-16;
  }

  .item {
    @apply flex items-center justify-between rounded-md border bg-white shadow;

    & + .item {
      @apply mt-2;
    }

    &__handle {
      @apply rounded-tr-md rounded-br-md bg-gray-100 px-3 py-4 text-supplement;

      &:hover {
        @apply cursor-move;
      }
    }

    &__content {
      @apply flex items-center px-6 py-4 font-medium;
    }
  }
}
</style>
