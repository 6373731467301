<template lang="pug">
  Row(lg="row")

    Column(lg="2/3")

      Card(title="Partner", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editDetails()")

        dl.dl-horizontal
          dt Name
          dd {{ partner.name }}
          dt Slug
          dd {{ partner.slug }}
          dt Contact email
          dd {{ partner.email }}
          dt Phone
          dd {{ partner.phone }}
          dt URL
          dd: a(v-if="partner.url", target="_blank", :href="partner.url") {{ partner.url }}
          dt Whitelist Domain(s)
          dd
            ul.list-comma-separated(v-if="partner.whitelist_domains")
              li(v-for="domain in partner.whitelist_domains") {{ domain }}
            span.text-danger(v-if="!partner.whitelist_domains") Embed disabled
          dt Same Tab Domain(s)
          dd
            ul.list-comma-separated(v-if="partner.same_tab_domains")
              li(v-for="domain in partner.same_tab_domains") {{ domain }}
            span(v-if="!partner.same_tab_domains") -

      Card(title="Explore", :noPadding="true")
        dl.dl-horizontal
          dt PID
          dd {{ partner.alt_id }}
          dt Domain
          dd.text-monospaced {{ partner.landing_page_domain || '-' }}

      Card(title="Inspires", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editInspires()")
        dl.dl-horizontal
          dt Logo URL
          dd.text-supplement Disabled
          //- dd {{ partner.inspires_logo_url }}
          dt Partner Details
          dd(v-html="$markdown(partner.inspires_partner_details)")
          dt CTAs
          dd {{ partner.inspires_allow_ctas ? 'Yes' : 'No' }}

      Card(title="Copilot", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editCopilot()")
        dl.dl-horizontal
          dt Display on Inspires
          dd
            Icon.text-success(v-if="partner.inspires_display_copilot", icon="check")
            Icon.text-danger(v-else, icon="times")
          dt Inspires Customisations
          dd
            Icon.text-success(v-if="partner.inspires_customisations_enabled", icon="check")
            Icon.text-supplement(v-else, icon="times")
          dt Cache Duration
          dd {{ cacheDurationLabel[partner.copilot_cache_duration] }}
          dt Advisor Branding
          dd {{ partner.copilot_advisor_branding || "Automatic" }}

      Card(title="AI", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editAi()")

        dl.dl-horizontal
          dt Brand Voice
          dd(:class="{'text-supplement': !partner.ai_brand_voice}") {{ partner.ai_brand_voice || '-' }}

      DataTable(ref="dataTable", title="Languages", :endpoint="`partners/${partner.id}/languages`", :sort="{ name: 'asc' }")
        template(v-slot:buttons)
          Button(type="primary", @click="newPartnerLanguage()") NEW LANGUAGE
        template(v-slot:rows="rows")
          DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
            DataTableColumn(title="Language") {{ row.language.name }}
            template(v-slot:actions)
              Dropdown.flip-menu(icon="ellipsis-v")
                DropdownLink(icon="pencil", @click="editPartnerLanguage(row.id)") Edit
                DropdownLink(icon="trash", @click="deletePartnerLanguage(row)") Delete

    Column(lg="1/3")

      Card(title="Account", :noPadding="true", v-if="partner.account_type")
        dl.dl-horizontal
          dt Type
          dd: router-link(:to="{name: 'account-types.view.permissions', params: {id: partner.account_type.id}}") {{ partner.account_type.name }}

      Card(title="Premium", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="pencil", @click="editPermissions()")
        dl.dl-horizontal
          dt Enhanced SEO Features
          dd: Icon(:icon="partner.has_premium_seo ? 'check' : 'times'", :class="partner.has_premium_seo ? 'text-success': 'text-supplement'")
          dt Itineraries
          dd: Icon(:icon="partner.has_premium_itineraries ? 'check' : 'times'", :class="partner.has_premium_itineraries ? 'text-success' : 'text-supplement'")
          dt Lead Generation / Surveys
          dd: Icon(:icon="partner.has_premium_forms ? 'check' : 'times'", :class="partner.has_premium_forms ? 'text-success' : 'text-supplement'")
          dt Premium Themes
          dd: Icon(:icon="partner.has_premium_themes ? 'check' : 'times'", :class="partner.has_premium_themes ? 'text-success' : 'text-supplement'")
          dt Travel Advisor Marketing
          dd: Icon(:icon="partner.has_premium_share ? 'check' : 'times'", :class="partner.has_premium_share ? 'text-success' : 'text-supplement'")
          dt Collaborators
          dd: Icon(:icon="partner.has_premium_collaborators ? 'check' : 'times'", :class="partner.has_premium_collaborators ? 'text-success' : 'text-supplement'")

      Card(title="API Information", :noPadding="true")
        template(v-slot:buttons)
          Button(prepend-icon="clipboard", v-clipboard="`${partner.api_key}:${partner.api_secret}`", v-clipboard:success="onCopy")
          Button(prepend-icon="sync", @click="refreshApiKeys()", v-tooltip="'Refresh API keys'")

        dl.dl-horizontal
          dt API Key
          dd {{ partner.api_key }}
          dt API Secret
          dd {{ partner.api_secret }}
          dt Last Accessed
          dd {{ partner.last_api_activity_at | datetime }}

      Card(title="Meta", :noPadding="true")
        dl.dl-horizontal
          dt ID
          dd {{ partner.id }}
          dt Hash ID
          dd {{ partner.hashid }}
          dt Added On
          dd {{ partner.created_at | datetime }}
          dt Updated At
          dd {{ partner.updated_at | datetime }}
</template>

<script>
import EditPartnerModal from "@/modals/Partners/EditPartnerModal"
import EditPartnerAiModal from "@/modals/Partners/EditPartnerAiModal"
import EditPartnerInspiresModal from "@/modals/Partners/EditPartnerInspiresModal"
import EditPartnerPermissionsModal from "@/modals/Partners/EditPartnerPermissionsModal"
import EditPartnerCopilotModal from "@/modals/Partners/EditPartnerCopilotModal.vue"
import NewPartnerLanguageModal from "@/modals/Partners/NewPartnerLanguageModal.vue"
import EditPartnerLanguageModal from "@/modals/Partners/EditPartnerLanguageModal.vue"

export default {
  metaInfo() {
    return { title: `${this.partner.name} Details` }
  },
  props: {
    partner: {}
  },
  data: () => ({
    cacheDurationLabel: {
      0: "Disabled",
      1: "1 minute",
      2: "2 minutes",
      3: "3 minutes",
      4: "4 minutes",
      5: "5 minutes",
      6: "6 minutes",
      7: "7 minutes",
      8: "8 minutes",
      9: "9 minutes",
      10: "10 minutes",
      15: "15 minutes",
      1440: "1 day",
      4320: "3 days",
      20160: "2 weeks",
      43200: "30 days",
      129600: "90 days",
      525600: "1 year"
    }
  }),
  methods: {
    async editDetails() {
      if (await this.$modal.show(EditPartnerModal, { endpoint: `partners/${this.partner.id}` })) {
        this.$emit("reload")
      }
    },
    async editPermissions() {
      if (
        await this.$modal.show(EditPartnerPermissionsModal, {
          endpoint: `partners/${this.partner.id}/permissions`
        })
      ) {
        this.$emit("reload")
      }
    },
    async editAi() {
      if (
        await this.$modal.show(EditPartnerAiModal, {
          endpoint: `partners/${this.partner.id}/ai`
        })
      ) {
        this.$emit("reload")
      }
    },
    async editInspires() {
      if (
        await this.$modal.show(EditPartnerInspiresModal, {
          endpoint: `partners/${this.partner.id}/inspires`
        })
      ) {
        this.$emit("reload")
      }
    },
    async editCopilot() {
      if (
        await this.$modal.show(EditPartnerCopilotModal, {
          endpoint: `partners/${this.partner.id}/copilot`
        })
      ) {
        this.$emit("reload")
      }
    },
    async refreshApiKeys() {
      if (
        await this.$modal.confirm(
          "Are you sure you would like to refresh the API keys for this partner? In doing so, the previous keys will no longer work."
        )
      ) {
        await this.$api.put(`partners/${this.partner.id}/refresh-api-keys`, { confirm: true })
        this.$emit("reload")
      }
    },
    onCopy() {
      this.$notification.success("Copied!")
    },
    async newPartnerLanguage() {
      if (
        await this.$modal.show(NewPartnerLanguageModal, {
          partner_id: this.partner.id
        })
      ) {
        this.$refs.dataTable.reload()
      }
    },
    async editPartnerLanguage(id) {
      if (await this.$modal.show(EditPartnerLanguageModal, { partner_id: this.partner.id, language_id: id })) {
        this.$refs.dataTable.reload()
      }
    },
    async deletePartnerLanguage(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.language.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`partners/${this.partner.id}/languages/${row.id}`)) {
          this.$notification.success(`**${row.language.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
