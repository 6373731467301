<template lang="pug">
  FormModal(title="New Content Depth", endpoint="content-depths", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Icon", name="icon", description="Choose an icon from <a href='https://fontawesome.com/icons?d=gallery' target='blank'>FontAwesome</a> and type the icon name without the prepended `fa-`. For example, `fa-book` should be inserted as `book`."): TextInput(v-model="data.icon")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name")
</template>

<script>
export default {
  data: () => ({
    data: {
      icon: "",
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Content depth **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
