<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Languages", endpoint="languages", :sort="{name: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newLanguage()") NEW LANGUAGE
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Name", sortable="name") {{ row.name }}
          DataTableColumn(title="Alpha2", sortable="alpha2") {{ row.alpha2 }}
          DataTableColumn(title="User Profile Enabled", sortable="user_profile_enabled"): BooleanIndicator(v-model="row.user_profile_enabled")
          DataTableColumn(title="Content", icon="content", sortable="content_count", width="max") {{ row.content_count | number | default('0') }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editLanguage(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteLanguage(row)") Delete
</template>

<script>
import NewLanguageModal from "@/modals/Languages/NewLanguageModal"
import EditLanguageModal from "@/modals/Languages/EditLanguageModal"
import BooleanIndicator from "@/components/BooleanIndicator"

export default {
  components: {
    BooleanIndicator
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Languages"
  }),
  methods: {
    async newLanguage() {
      if (await this.$modal.show(NewLanguageModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editLanguage(id) {
      if (await this.$modal.show(EditLanguageModal, { endpoint: `languages/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteLanguage(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`languages/${row.id}`)) {
          this.$notification.success(`Language **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
