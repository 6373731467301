<template lang="pug">
  div.app(:class="`app-sidebar--${sidebar}`")
    BreakpointHelper
    Header
    div.app-body
      Sidebar
      main.app-body-content
        div.page
          header.page-header(:class="{'has-tabs': $scopedSlots.tabs}")
            div.page-header-row
              div.page-header-content
                slot(name="header-content")
                  slot(name="title")
                    h1.page-header-title(v-if="title") {{ title }}
                  slot(name="description")
                    p.page-header-description(v-if="description") {{ description }}
              div.page-header-buttons
                slot(name="buttons")
            div.page-header-row
              div.page-header-tabs(v-if="$scopedSlots.tabs")
                slot(name="tabs")
          main.page-content
            slot
</template>

<script>
import BreakpointHelper from "@syntax51/app-kit/components/BreakpointHelper"
import Header from "./partials/Header"
import Sidebar from "./partials/Sidebar"
import { titleTemplate } from "@/config"
import { mapGetters } from "vuex"

export default {
  metaInfo: {
    titleTemplate
  },
  components: {
    BreakpointHelper,
    Header,
    Sidebar
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: String
  },
  computed: {
    ...mapGetters({
      sidebar: "sidebar/style"
    })
  }
}
</script>

<style>
.app {
  &-body {
    @apply flex min-h-screen bg-gray-300;
    margin-left: theme("sidebar.width");

    &-content {
      @apply w-full;
      margin-top: theme("header.height");
    }
  }

  &.app-sidebar--compact {
    .app-header-logo,
    .app-sidebar,
    .app-sidebar-body {
      width: theme("header.height");
    }

    .navigation-heading {
      @apply hidden;
    }

    .navigation-link {
      @apply justify-center px-0;

      &-icon {
        @apply mr-0;
      }

      &-content {
        @apply hidden;
      }
    }

    .app-body {
      margin-left: theme("header.height");
    }
  }

  .page-content > .row + .card {
    @apply mt-6 lg:mt-12;
  }

  .page-content {
    .column {
      @apply lg:px-6;
    }

    .row {
      @apply lg:-mx-6;

      &.flex-col {
        .column + .column {
          @apply lg:mt-12;
        }
      }

      @each $screen, $size in (sm, md, lg, xl), (640px, 768px, 1024px, 1280px) {
        @media (min-width: $size) {
          &.$(screen)\:flex-col {
            .column + .column {
              @apply lg:mt-12;
            }
          }
        }
      }

      @each $screen, $size in (sm, md, lg, xl), (640px, 768px, 1024px, 1280px) {
        @media (min-width: $size) {
          &.$(screen)\:flex-row {
            .column + .column {
              @apply mt-0;
            }
          }
        }
      }
    }
  }
}

.page {
  @apply flex h-full w-full flex-col;

  &-header,
  &-content {
    @apply w-full p-16;
  }

  &-header {
    @apply bg-white py-12 shadow;

    &.has-tabs {
      @apply pb-0;
    }

    &-title {
      @apply text-xl;
    }

    &-row {
      @apply flex items-center justify-between;
    }

    &-content {
      @apply w-full;
    }

    &-description {
      @apply mt-2;
      max-width: 50%;
    }

    &-buttons {
      @apply -mx-1 flex items-center;

      .button {
        @apply mx-1;
      }
    }

    &-tabs {
      @apply mt-6 flex w-full;

      > a {
        @apply block border-b border-transparent px-4 py-3 font-medium text-paragraph;
        border-bottom-width: 2px;
        margin-bottom: -2px;

        &.router-link-active {
          @apply cursor-default text-primary-default;
          border-bottom-color: theme("colors.primary-default");
        }
      }
    }
  }

  &-content {
    @apply relative flex h-full flex-col;

    > .row {
      & + .card {
        @apply mt-12;
      }
    }
  }
}
</style>
