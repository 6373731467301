<template lang="pug">
  div.dashboard-user-list(:class="{'dashboard-user-list-status': usersHaveStatus}")
    div.loading(v-show="loading") Loading&hellip;
    div.no-results(v-show="!loading && !count") {{ noResults }}
    ul.users(v-show="!loading && count")
      li.user(v-for="user in users", :key="user.id", :class="user.type")
        div.user-avatar(:style="{'background-color': user.partner.brand_color}")
          span {{ user.initials || '??' }}
          span.user-status(v-if="usersHaveStatus", :class="user.status")
        div.user-info
          router-link.user-name(v-if="user.type != 'admin'", :to="{name: 'users.view', params: {id: user.id}}") {{ user.full_name }}
          span.user-name(v-else) {{ user.full_name }}
          router-link.user-partner(v-if="user.partner", :to="{name: 'partners.view', params: {id: user.partner.id}}") {{ user.partner.name }}
          span.user-admin(v-if="user.admin") Approach Guides Admin
          span.user-meta(v-if="user.meta", v-tooltip="user.hover_meta") {{ user.meta }}
</template>

<script>
import { get, size } from "lodash-es"

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    noResults: {
      type: String,
      default: "There are no users to display."
    }
  },
  data: () => ({
    loading: true,
    users: []
  }),
  computed: {
    count() {
      return size(this.users)
    },
    usersHaveStatus() {
      return this.users.some(user => Object.prototype.hasOwnProperty.call(user, "status"))
    }
  },
  async mounted() {
    let response = await this.$api.get(this.endpoint)
    this.users = get(response, "data.items")
    this.loading = false
  }
}
</script>

<style>
.dashboard-user-list {
  .users {
    @apply flex flex-col;
  }

  .user {
    @apply flex;

    &-status,
    &-partner,
    &-admin,
    &-meta {
      @apply block;
    }

    &-avatar {
      @apply relative mr-4 mt-1 flex h-12 w-12 items-center justify-center rounded-full bg-primary-default text-xs font-semibold text-white transition-colors;
    }

    &-status {
      @apply absolute left-0 top-0 -ml-1 -mt-1 h-4 w-4 rounded-full border border-2 border-white bg-green-400;

      &.idle {
        @apply bg-orange-400;
      }
    }

    &-name {
      @apply mr-2 font-medium;
    }

    &-partner {
      @apply text-paragraph;
    }

    &-meta {
      @apply mt-1 text-sm text-supplement;
    }

    & + .user {
      @apply mt-5 border-t border-dotted pt-5;
    }
  }
}
</style>
