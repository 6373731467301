<template>
  <Icon :icon="value ? 'check' : 'times'" :class="value ? 'text-success' : 'text-gray-400'" />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    }
  }
}
</script>
