<template lang="pug">
  FormModal(title="New User Template", :endpoint="endpoint", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name")
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    data: {
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`User template **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
