<template lang="pug">
  div.logo
    img.content__header-logo(v-show="sidebar === 'compact'", src="~@/assets/images/logo-compact.png", :alt="appName", width="180")
    img.content__header-logo(v-show="sidebar !== 'compact'", src="~@/assets/images/logo.png", :alt="appName", width="180")
</template>

<script>
import { appName } from "@/config"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      sidebar: "sidebar/style"
    })
  },
  data: () => ({
    appName
  })
}
</script>
