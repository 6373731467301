<template lang="pug">
  AppLayout(:title="title")
    DataTable(title="Expired Content", endpoint="content", :filters="filters", :params="params", searchable, :sort="{ expires_at: 'desc' }")
      template(v-slot:tabs)
        DataTableTabs(v-model="params.filters.in_collection")
          DataTableTab(value="all") All Content
          DataTableTab(value="in") In Collection
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Title", sortable="title"): router-link(:to="{name: 'content.edit', params: {id: row.id}}") {{ row.title }}
          DataTableColumn(title="Type", icon="th-large", sortable="type_id", width="max")
            div.content-type(v-if="row.type")
              Icon.content-type__icon(:icon="row.type.icon")
              span.content-type__label {{ row.type.name }}
          DataTableColumn(title="Libraries", icon="books")
            ul.list-comma-separated: li(v-for="library in row.libraries") {{ library }}
            span(v-if="!row.libraries.length") -
          DataTableColumn(icon="destination", sortable="destinations_count", width="max") {{ row.destinations_count | number }}
          DataTableColumn(icon="collection", sortable="collections_count", width="max") {{ row.collections_count | number }}
          DataTableColumn(title="Expired", sortable="expires_at", width="max") {{ row.expires_at | datetime('medium') }}
          template(v-slot:actions)
            router-link(:to="{name: 'content.edit', params: {id: row.id}}"): Icon(icon="chevron-right")
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-expired-content" } }
  },
  data: () => ({
    title: "Expired Content",
    filters: {},
    params: {
      filters: {
        in_collection: "all",
        published: "expired"
      }
    }
  })
}
</script>

<style>
.page-expired-content {
  .content-type {
    @apply flex items-center;

    &__icon {
      @apply mr-3 w-5 text-primary-default;
    }

    &__label {
      @apply text-sm;
    }
  }
}
</style>
