<template lang="pug">
  FormModal(title="New User", endpoint="users", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="User Template", name="user_template_id"): Select(v-model="data.user_template_id", :endpoint="userTemplatesEndpoint", prepend-icon="user-circle", :disabled="!data.partner_id")
    div.grid.grid-cols-2.gap-x-8
      InputGroup(label="First Name", name="first_name"): TextInput(v-model="data.first_name")
      InputGroup(label="Last Name", name="last_name"): TextInput(v-model="data.last_name")
    InputGroup(label="Email", name="email"): TextInput(v-model="data.email", prepend-icon="envelope", placeholder="name@site.com")
    InputGroup(label="Phone", name="phone"): TextInput(v-model="data.phone", prepend-icon="phone", placeholder="+country code + number")
    div.grid.grid-cols-2.gap-x-8
      InputGroup.mb-4(label="Password", name="password"): PasswordInput(v-model="data.password", prepend-icon="lock")
      InputGroup.mb-4(label="Confirm Password", name="password_confirmation"): PasswordInput(v-model="data.password_confirmation", prepend-icon="lock")
    p.mb-8.text-sm.text-supplement Suggested: #[code.bg-gray-100.mx-1.px-2.py-1.rounded {{ randomPassword }}] #[CopyToClipboardIcon(:content="randomPassword")]
    InputGroup(label="Landing Page Sharing", name="display_contact_info_on_links")
      Radios(v-model="data.display_contact_info_on_links")
        Radio(value="personal") Include my personal contact information
        Radio(value="company") Include company contact information
        Radio(value="none") Don't include any contact information
    InputGroup(label="Live Editor Display", name="live_editor_display")
      Radios(v-model="data.live_editor_display")
        Radio(value="landing-page")
          span Landing Page #[span.text-supplement &mdash; header #[strong.font-semibold enabled], opens #[strong.font-semibold header] tab]
        Radio(value="embed")
          span Embed #[span.text-supplement &mdash; header #[strong.font-semibold disabled], opens #[strong.font-semibold content] tab]
</template>

<script>
export default {
  props: {
    partner: Object
  },
  data: () => ({
    data: {
      partner_id: "",
      user_template_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      live_editor_display: "landing-page",
      display_contact_info_on_links: "none"
    }
  }),
  mounted() {
    this.data.partner_id = this.partner && this.partner.id
  },
  computed: {
    randomPassword() {
      let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      return [...Array(10)].reduce(a => a + chars[~~(Math.random() * chars.length)], "")
    },
    userTemplatesEndpoint() {
      return (
        this.partner &&
        this.partner.account_type_id &&
        `account-types/${this.partner.account_type_id}/user-templates-options`
      )
    }
  },
  methods: {
    success(result) {
      this.$notification.success(`User **${result.item.full_name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
