<template>
  <FormModal
    :data="data"
    :endpoint="endpoint"
    :title="title || 'Update User'"
    method="PUT"
    v-bind="$attrs"
    @success="success"
  >
    <div v-if="form === 'account'">
      <InputGroup label="User Template" name="user_template_id">
        <Select
          v-model="data.user_template_id"
          :disabled="!data.partner_id"
          :endpoint="userTemplatesEndpoint"
          prepend-icon="user-circle"
        ></Select>
      </InputGroup>
    </div>

    <div v-else-if="form === 'details'">
      <div class="grid grid-cols-2 gap-x-8">
        <InputGroup label="First Name" name="first_name">
          <TextInput v-model="data.first_name"></TextInput>
        </InputGroup>

        <InputGroup label="Last Name" name="last_name">
          <TextInput v-model="data.last_name"></TextInput>
        </InputGroup>
      </div>

      <InputGroup label="Email" name="email">
        <TextInput v-model="data.email" placeholder="name@site.com" prepend-icon="envelope" type="email"></TextInput>
      </InputGroup>

      <InputGroup label="Phone" name="phone">
        <TextInput
          v-model="data.phone"
          placeholder="+country code + number"
          prepend-icon="phone"
          type="tel"
        ></TextInput>
      </InputGroup>

      <InputGroup label="Company" name="company_name">
        <TextInput v-model="data.company_name" prepend-icon="building"></TextInput>
      </InputGroup>

      <InputGroup label="Language(s)" name="language_ids">
        <MultiSelect
          v-model="data.language_ids"
          :spellcheck="false"
          endpoint="options/user-languages"
          placeholder="Enter language(s)..."
          prepend-icon="language"
        />
      </InputGroup>

      <InputGroup label="Country" name="country_id">
        <Select v-model="data.country_id" endpoint="options/countries" prepend-icon="country" />
      </InputGroup>

      <InputGroup label="Host Agency" name="host_agency_id">
        <Select v-model="data.host_agency_id" endpoint="options/host-agencies" prepend-icon="host-agencies" />
      </InputGroup>

      <InputGroup label="Consortium" name="consortium_id">
        <Select v-model="data.consortium_id" endpoint="options/consortia" prepend-icon="consortium" />
      </InputGroup>
    </div>

    <div v-else-if="form === 'explore'">
      <InputGroup label="Landing Page Sharing" name="display_contact_info_on_links">
        <Radios v-model="data.display_contact_info_on_links">
          <Radio value="personal">Include my personal contact information</Radio>
          <Radio value="company">Include company contact information</Radio>
          <Radio value="none">Don't include any contact information</Radio>
        </Radios>
      </InputGroup>

      <InputGroup label="Live Editor Display" name="live_editor_display">
        <Radios v-model="data.live_editor_display">
          <Radio value="landing-page">
            <span>Landing Page</span>
            <span class="text-supplement">
              &mdash; header
              <strong class="font-semibold">enabled</strong>, opens
              <strong class="font-semibold">header</strong>
              tab
            </span>
          </Radio>
          <Radio value="embed">
            <span>Embed</span>
            <span class="text-supplement">
              &mdash; header
              <strong class="font-semibold">disabled</strong>, opens
              <strong class="font-semibold">content</strong>
              tab
            </span>
          </Radio>
        </Radios>
      </InputGroup>
    </div>

    <div v-else-if="form === 'travelAdvisor'">
      <InputGroup label="Travel Advisor" name="is_travel_advisor">
        <ToggleSwitch v-model="data.is_travel_advisor" label="User is a travel advisor" />
      </InputGroup>

      <InputGroup v-if="data.is_travel_advisor" label="Booking Email" name="booking_email">
        <TextInput v-model="data.booking_email"></TextInput>
      </InputGroup>

      <InputGroup v-if="data.is_travel_advisor" label="Booking Phone Number" name="booking_phone_number">
        <TextInput v-model="data.booking_phone_number"></TextInput>
      </InputGroup>

      <InputGroup v-if="data.is_travel_advisor" label="Booking IATA" name="booking_iata">
        <TextInput v-model="data.booking_iata"></TextInput>
      </InputGroup>

      <InputGroup v-if="data.is_travel_advisor" label="Booking CLIA" name="booking_clia">
        <TextInput v-model="data.booking_clia"></TextInput>
      </InputGroup>
    </div>

    <div v-else-if="form === 'media'">
      <InputGroup label="Default Logo" name="logo">
        <ImageInput v-model="data.logo" />
      </InputGroup>

      <InputGroup label="Light Logo" name="logo_light">
        <ImageInput v-model="data.logo_light" />
      </InputGroup>

      <InputGroup label="Header Background Image" name="header_background_image">
        <ImageInput v-model="data.header_background_image" />
      </InputGroup>

      <InputGroup label="Personal Photo" name="personal_photo">
        <ImageInput v-model="data.personal_photo" />
      </InputGroup>
    </div>
  </FormModal>
</template>

<script>
import { pick } from "@/utils"

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    form: {
      type: String,
      default: "details"
    },
    title: {
      type: String
    }
  },
  watch: {
    "data.partner_id": "fetchPartner"
  },
  data: () => ({
    loading: true,
    data: {},
    partner: {},
    formFields: {
      account: ["user_template_id"],
      details: [
        "first_name",
        "last_name",
        "email",
        "phone",
        "company_name",
        "host_agency_id",
        "host_agency_other",
        "consortium_id"
      ],
      explore: ["display_contact_info_on_links", "live_editor_display"],
      travelAdvisor: ["is_travel_advisor", "booking_email", "booking_phone_number", "booking_iata", "booking_clia"],
      media: ["logo", "logo_light", "header_background_image", "personal_photo"]
    }
  }),
  async created() {
    let { data } = await this.$api.get(this.endpoint)
    const user = data.data

    this.data = pick(user, this.formFields[this.form])

    if (this.form === "account") {
      this.data.partner_id = user.partner.id
      this.data.user_template_id = user.user_template.id
    } else if (this.form === "details") {
      this.data.host_agency_id = user.hostAgency?.id
      this.data.language_ids = user.languages?.map(language => language.id)
      this.data.consortium_id = user.consortium?.id
      this.data.country_id = user.country?.id
    }

    this.loading = false
  },
  computed: {
    userTemplatesEndpoint() {
      return (
        this.partner &&
        this.partner.account_type_id &&
        `account-types/${this.partner.account_type_id}/user-templates-options`
      )
    }
  },
  methods: {
    async fetchPartner() {
      if (!this.data.partner_id) return

      let { data } = await this.$api.get(`partners/${this.data.partner_id}`)
      this.partner = data.item
    },
    success(response) {
      this.$notification.success(`**${response.data.first_name} ${response.data.last_name}** has been updated.`)
      this.$emit("close", response.data)
    }
  }
}
</script>
