<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", :title="title", endpoint="partners", :filters="filters", :params="params", :sort="{name: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newPartner()") NEW PARTNER
      //- template(v-slot:filters)
        DataTableFilter(v-model="filters.accountType", endpoint="options/account-types", label="Account Type", prepend-option="All Account Types")
      template(v-slot:tabs)
        DataTableTabs(v-model="params.filters.accountType")
          DataTableTab(value="all") All Partners
          DataTableTab(v-for="tab in tabs", :key="tab.id", :value="tab.id") {{ tab.name }}
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Partner", sortable="name")
            router-link(:to="{name: 'partners.view', params: {id: row.id}}") {{ row.name }}
            div.text-sm.text-supplement {{ row.account_type && row.account_type.name }}
          DataTableColumn(title="Contact")
            div {{ row.email }}
            div {{ row.phone | default('-') }}
          DataTableColumn(title="PID") {{ row.alt_id }} #[CopyToClipboardIcon(:content="row.alt_id")]
          DataTableColumn(icon="collection", sortable="collections_count", sort-order="desc", width="max") {{ row.collections_count | number }}
          DataTableColumn(icon="user", sortable="users_count", sort-order="desc", width="max") {{ row.users_count | number }}
          DataTableColumn(icon="badge-check", sortable="shared_collections_count", sort-order="desc", width="max") {{ row.shared_collections_count | number }}
          DataTableColumn(title="Accessed", sortable="last_login_at", sort-order="desc", width="max") {{ row.last_login_at | datetime }}
          DataTableColumn(title="Added", sortable="created_at", sort-order="desc", width="max") {{ row.created_at | datetime }}
          template(v-slot:actions): router-link(:to="{name: 'partners.view', params: {id: row.id}}"): Icon(icon="chevron-right")
</template>

<script>
import NewPartnerModal from "@/modals/Partners/NewPartnerModal"
// import { filter } from "lodash-es"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Partners",
    tabs: [],
    filters: {},
    params: {
      filters: {
        accountType: "all"
      }
    }
  }),
  created: async function () {
    this.tabs = await this.$api.values("options/account-types")
    // @todo: David to finish.
    // let tabFilterAccountTypeIds = [1, 2, 3]
    // this.tabs = filter(response.data.items, item => tabFilterAccountTypeIds.indexOf(item.id) != -1)
  },
  methods: {
    async newPartner() {
      if (await this.$modal.show(NewPartnerModal)) {
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>
