<template>
  <FormModal title="New Region" endpoint="regions" :data="data" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" placeholder="United States" />
    </InputGroup>

    <InputGroup name="country_ids" label="Countrie(s)">
      <MultiSelect
        v-model="data.country_ids"
        placeholder="Add countries to this region"
        endpoint="search/countries"
        searchable
      />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  data: () => ({
    data: {
      name: null,
      country_ids: []
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Region **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
