<template>
  <Icon :icon="`block-${type}`" :class="`block-type-icon block-type-icon--${type}`" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.block-type-icon {
  @apply grid w-8 place-items-center;

  &--content {
    @apply text-blocks-content;
  }

  &--cta {
    @apply text-blocks-cta;
  }

  &--form {
    @apply text-blocks-form;
  }

  &--heading {
    @apply text-blocks-heading;
  }

  &--image {
    @apply text-blocks-image;
  }

  &--itinerary {
    @apply text-blocks-itinerary;
  }

  &--note {
    @apply text-blocks-note;
  }

  &--text {
    @apply text-blocks-text;
  }
}
</style>
