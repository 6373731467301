<template lang="pug">
  FormModal(title="Edit Destination", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Parent Destination", name="parent_id"): Select(v-model="data.parent_id", endpoint="options/destinations", prepend-icon="destination")
    InputGroup(label="Destination", name="name"): TextInput(v-model="data.name", prepend-icon="destination")
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      parent_id: "",
      name: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Destination **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
