<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Partner Markets", endpoint="partner-markets", :sort="{partner_id: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newPartnerMarket()") NEW PARTNER MARKET
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Partner", sortable="partner_id")
            router-link(:to="{name: 'partners.view', params: {id: row.partner.id}}") {{ row.partner.name }}
          DataTableColumn(title="Name", sortable="name") {{ row.name }}
          DataTableColumn(title="Include If No Match", sortable="include_if_no_match"): BooleanIndicator(v-model="row.include_if_no_match")
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editPartnerMarket(row.id)") Edit
              DropdownLink(icon="trash", @click="deletePartnerMarket(row)") Delete
</template>

<script>
import NewPartnerMarketModal from "@/modals/PartnerMarkets/NewPartnerMarketModal"
import EditPartnerMarketModal from "@/modals/PartnerMarkets/EditPartnerMarketModal"
import BooleanIndicator from "@/components/BooleanIndicator"

export default {
  components: {
    BooleanIndicator
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Partner Markets"
  }),
  methods: {
    async newPartnerMarket() {
      if (await this.$modal.show(NewPartnerMarketModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editPartnerMarket(id) {
      if (await this.$modal.show(EditPartnerMarketModal, { endpoint: `partner-markets/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deletePartnerMarket(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`partner-markets/${row.id}`)) {
          this.$notification.success(`Partner market **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
