<template lang="pug">
  Modal(title="Add Selected Content To Publishers", v-bind="$attrs")
    DataTable(ref="dataTable", title="Publishers", endpoint="publishers", :per-page="10", :sort="{name: 'asc'}", searchable)
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Publisher", sortable="name") {{ row.name }}
          template(v-slot:actions)
            Button(v-if="added_to.indexOf(row.id) == -1", size="sm", type="primary", @click="addToPublisher(row.id)"): Icon(icon="plus")
            Button(v-else, size="sm", type="default", disabled): Icon.text-success(icon="check")
</template>
<script>
export default {
  props: {
    content_ids: Array
  },
  data: () => ({
    added_to: []
  }),
  methods: {
    async addToPublisher(publisher_id) {
      this.added_to.push(publisher_id)
      this.$notification.success(`The selected content has been added to this publisher.`)
      await this.$api.post(`publishers/${publisher_id}/content`, { content_ids: this.content_ids })
    }
  }
}
</script>
