<template lang="pug">
  FormModal(title="New Library", endpoint="libraries", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Owner", name="partner_id"): Select(v-model="data.partner_id", endpoint="options/partners")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name")
    InputGroup(label="Partner(s)", name="partners_ids"): MultiSelect(v-model="data.partners_ids", endpoint="options/partners")
</template>

<script>
export default {
  data: () => ({
    data: {
      partner_id: "",
      name: "",
      partners_ids: []
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Library **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
