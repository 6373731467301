<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Shared Collections", endpoint="shared-collections", :sort-by="{'created_at': 'desc'}", :params="params", searchable)
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Shared Collection", icon="collection")
            router-link(:to="{name: 'shared-collections.view', params: {id: row.id}}", v-html="highlight(row.collection.name)", class="font-medium")
            div.flex.space-x-6.mt-2.text-sm
              router-link(:to="{name: 'partners.view', params: {id: row.collection.partner.id}}", class="text-supplement")
                Icon.mr-2(icon="users")
                span(v-html="highlight(row.collection.partner.name)")
              router-link(:to="{name: 'users.view', params: {id: row.user.id}}", class="text-supplement")
                Icon.mr-2(icon="user")
                span(v-html="highlight(row.user.full_name || row.user.email.toLowerCase())")
          DataTableColumn(title="Personal Message", sortable="has_personal_message"): BooleanIndicator(v-model="row.has_personal_message")
          DataTableColumn(title="Personalizations", sortable="personalisations_count", :class="{'text-danger font-medium': !row.personalisations_count}") {{ row.personalisations_count }}
          DataTableColumn(title="Accepted", icon="clock", sortable="created_at") {{ row.created_at_diff }}
</template>

<script>
import BooleanIndicator from "@/components/BooleanIndicator"

export default {
  components: {
    BooleanIndicator
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Shared Collections",
    params: {
      filters: {
        query: ""
      }
    }
  }),
  methods: {
    highlight(value) {
      return this.$options.filters.highlight(value, this.params.filters.query)
    }
  }
}
</script>

<style lang="postcss">
.highlight {
  @apply bg-primary-100;
}
</style>
