<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Content Depths", endpoint="content-depths", :sort="{order: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newContentDepth()") NEW CONTENT DEPTH
        Button(type="secondary", @click="reorderContentDepths()") REORDER
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Content Depth", icon="contentDepth", sortable="name")
            div.content-depth
              div.content-depth__icon: Icon(:icon="row.icon")
              div
                p {{ row.name }}
                p {{ row.description }}
          DataTableColumn(title="Order", icon="weight-hanging", width="max", sortable="order") {{ row.order | number | default('0') }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editContentDepth(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteContentDepth(row)") Delete
</template>

<script>
import NewContentDepthModal from "@/modals/ContentDepths/NewContentDepthModal"
import EditContentDepthModal from "@/modals/ContentDepths/EditContentDepthModal"
import ReorderContentDepthsModal from "@/modals/ContentDepths/ReorderContentDepthsModal"

export default {
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-content-depths" } }
  },
  data: () => ({
    title: "Content Depths"
  }),
  methods: {
    async newContentDepth() {
      if (await this.$modal.show(NewContentDepthModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editContentDepth(id) {
      if (await this.$modal.show(EditContentDepthModal, { endpoint: `content-depths/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteContentDepth(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`content-depths/${row.id}`)) {
          this.$notification.success(`Content depth **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    },
    async reorderContentDepths() {
      await this.$modal.show(ReorderContentDepthsModal)
      this.$refs.dataTable.reload()
    }
  }
}
</script>

<style>
.page-content-depths {
  .content-depth {
    @apply flex items-center;

    &__icon {
      @apply mr-3 w-6 flex-shrink-0 text-center text-lg text-primary-default;
    }
  }
}
</style>
