<template>
  <FormModal
    title="New Country Region"
    :endpoint="`countries/${countryId}/country-regions`"
    :data="data"
    v-bind="$attrs"
    @success="success"
  >
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" />
    </InputGroup>

    <InputGroup label="Abbreviation" name="abbreviation">
      <TextInput v-model="data.abbreviation" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    countryId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: {
      name: "",
      abbreviation: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`**${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
