<template>
  <FormModal
    title="Edit Partner Market"
    :endpoint="endpoint"
    method="PUT"
    :data="data"
    v-bind="$attrs"
    @success="success"
  >
    <InputGroup label="Partner" name="partner_id">
      <Select v-model="data.partner_id" endpoint="options/partners" />
    </InputGroup>

    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" placeholder="United States" />
    </InputGroup>

    <InputGroup name="country_ids" label="Countrie(s)">
      <MultiSelect
        v-model="data.country_ids"
        placeholder="Add countries to this partner market"
        endpoint="search/countries"
        searchable
      />
    </InputGroup>

    <InputGroup name="language_ids" label="Language(s)">
      <MultiSelect
        v-model="data.language_ids"
        placeholder="Add languages to this partner market"
        endpoint="search/languages"
        searchable
      />
    </InputGroup>

    <InputGroup name="region_ids" label="Region(s)">
      <MultiSelect
        v-model="data.region_ids"
        placeholder="Add regions to this partner market"
        endpoint="search/regions"
        searchable
      />
    </InputGroup>

    <InputGroup label="Include Options">
      <ToggleSwitch v-model="data.include_if_no_match" label="Include only if no partner market matches" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {
      partner_id: null,
      name: null,
      include_if_no_match: null,
      country_ids: [],
      language_ids: [],
      region_ids: []
    }
  }),
  async created() {
    const { data } = await this.$api.get(this.endpoint)

    this.data.partner_id = data.data.partner_id
    this.data.name = data.data.name
    this.data.include_if_no_match = data.data.include_if_no_match
    this.data.country_ids = data.data.countries.map(country => country.id)
    this.data.language_ids = data.data.languages.map(country => country.id)
    this.data.region_ids = data.data.regions.map(country => country.id)

    this.loading = false
  },
  methods: {
    created() {},
    success(result) {
      this.$notification.success(`Partner market **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
