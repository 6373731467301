<template>
  <AppLayout :title="title" class="page-add-edit-image">
    <template v-slot:buttons v-if="mode === 'edit'">
      <Button type="danger" prepend-icon="trash" @click="onDelete()" />
    </template>

    <div class="p-16">
      <Form
        :method="method"
        :endpoint="endpoint"
        :data="data"
        ref="form"
        no-footer
        @error="onFormError"
        @success="onFormSuccess"
      >
        <Row lg="row">
          <Column lg="2/3">
            <Card title="Image">
              <InputGroup label="Image" name="featured_image" description="Max upload size: 10MB">
                <ImageInput v-model="data.featured_image" @input="onImageChange" />
              </InputGroup>
            </Card>

            <Card title="Description">
              <InputGroup name="title" label="Title" optional>
                <TextInput v-model="data.title" />
              </InputGroup>

              <InputGroup name="synopsis" label="Caption" optional="Optional, Recommended">
                <TextInput v-model="data.synopsis" textarea :rows="3" />
              </InputGroup>

              <InputGroup name="tag_ids" label="Subject Area(s)" optional>
                <MultiSelect
                  v-model="data.tag_ids"
                  placeholder="Enter subject area(s)..."
                  endpoint="options/tags"
                  :spellcheck="false"
                />
              </InputGroup>

              <InputGroup name="destination_ids" label="Destination(s)" optional>
                <MultiSelect
                  v-model="data.destination_ids"
                  placeholder="Enter destination(s)..."
                  endpoint="search/destinations"
                  option-label="label"
                  :spellcheck="false"
                  searchable
                />
              </InputGroup>
            </Card>

            <Card title="Access & Visibility">
              <InputGroup name="collection_ids" label="Collection(s)" optional>
                <MultiSelect
                  v-model="data.collection_ids"
                  placeholder="Enter collections(s)..."
                  endpoint="search/collections"
                  :spellcheck="false"
                  searchable
                />
              </InputGroup>

              <InputGroup name="library_ids" label="Libraries">
                <MultiSelect
                  v-model="data.library_ids"
                  placeholder="Add this to a library..."
                  endpoint="options/libraries"
                />
              </InputGroup>

              <InputGroup name="partner_id" label="Owner">
                <Select v-model="data.partner_id" endpoint="options/partners" prepend-icon="users" />
              </InputGroup>
            </Card>

            <Card title="Legal - Usage & Rights" optional>
              <p class="subtitle">Prefill</p>
              <div>
                <span><a @click="prefillData('ag')">Approach Guides</a>, </span>
                <span><a @click="prefillData('flickr')">Flickr</a>, </span>
                <span><a @click="prefillData('pexels')">Pexels</a>, </span>
                <span><a @click="prefillData('unsplash')">Unsplash</a>, </span>
                <span><a @click="prefillData('wikimedia-commons')">Wikimedia Commons</a>, </span>
                <span><a @click="prefillData('(adapted)')">(adapted)</a>, </span>
                <span>or <a @click="prefillData('clear')">clear</a></span>
              </div>

              <p class="subtitle mt-8">Source</p>
              <Row lg="row">
                <Column width="1/2">
                  <InputGroup name="image_source" label="Source">
                    <TextInput v-model="data.image_source" />
                  </InputGroup>
                </Column>

                <Column width="1/2">
                  <InputGroup name="image_source_url" label="Source URL">
                    <TextInput v-model="data.image_source_url" />
                  </InputGroup>
                </Column>
              </Row>

              <p class="subtitle">License</p>
              <Row lg="row">
                <Column width="1/2">
                  <InputGroup name="image_license" label="License">
                    <TextInput v-model="data.image_license" />
                  </InputGroup>
                </Column>

                <Column width="1/2">
                  <InputGroup name="image_license_url" label="License URL">
                    <TextInput v-model="data.image_license_url" />
                  </InputGroup>
                </Column>
              </Row>

              <p class="subtitle">Other</p>
              <InputGroup name="image_attribution" label="Attribution">
                <TextInput v-model="data.image_attribution" />
              </InputGroup>

              <InputGroup name="image_modifications" label="Modifications">
                <TextInput v-model="data.image_modifications" />
              </InputGroup>
            </Card>
          </Column>
          <Column lg="1/3">
            <div class="sticky">
              <Card v-if="featuredImagePreview" title="Image Preview">
                <a :href="featuredImagePreview" target="_blank">
                  <img :src="featuredImagePreview" />
                </a>
              </Card>
              <Card title="Original">
                <InputGroup name="original">
                  <Checkbox v-model="data.original">
                    <span class="inline-block text-right align-top leading-tight">
                      <strong>approach</strong>guides<br /><strong class="text-xs text-primary">ORIGINAL</strong>
                    </span>
                  </Checkbox>
                </InputGroup>
              </Card>
            </div>
          </Column>
        </Row>
      </Form>
    </div>
    <div class="sticky bottom-0 mt-auto">
      <Alert v-show="errors" type="danger" title="Oops!" class="mx-8 mb-8 bg-white shadow" dismissible>
        There was an error. Please review the form for more details.
      </Alert>
      <div class="border-t border-gray-400 bg-white p-6 text-right">
        <Button type="primary" @click="onSaveButtonClick">SAVE</Button>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { Alert } from "@syntax51/app-kit"

export default {
  watch: {
    $route: "fetch"
  },
  components: {
    Alert
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    data: {
      featured_image: null,
      title: null,
      synopsis: null,
      destination_ids: [],
      collection_ids: [],
      library_ids: [1],
      partner_id: 1,
      image_source: null,
      image_source_url: null,
      image_license: null,
      image_license_url: null,
      image_attribution: null,
      image_modifications: null,
      tag_ids: []
    },
    featured_image_preview: null,
    errors: null
  }),
  computed: {
    mode() {
      return this.$route.name === "image.edit" ? "edit" : "add"
    },
    title() {
      return `${this.verb} Image`
    },
    verb() {
      return this.mode === "edit" ? "Edit" : "Add"
    },
    method() {
      return this.mode === "edit" ? "PUT" : "POST"
    },
    endpoint() {
      return this.mode === "edit" ? `images/${this.$route.params.id}` : "images"
    },
    featuredImagePreview() {
      return this.data.featured_image?.full_url || this.data.featured_image_preview?.full_url
    }
  },
  async created() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      if (this.mode === "add") return

      let response = await this.$api.get(this.endpoint)
      if (response?.data?.item) this.data = response.data.item
    },
    prefillData(type) {
      if (type === "ag") {
        this.data.image_license = "© Approach Guides"
        this.data.original = true
      } else if (type === "flickr") {
        this.data.original = false
        this.data.image_source = "Flickr"
        this.data.image_source_url = "REQUIRED"
        this.data.image_license = "REQUIRED"
        this.data.image_license_url = "REQUIRED"
        this.data.image_attribution = "REQUIRED: Author Name"
      } else if (type === "pexels") {
        this.data.original = false
        this.data.image_source = "Pexels"
        this.data.image_source_url = "REQUIRED"
        this.data.image_license = "License"
        this.data.image_license_url = "https://www.pexels.com/license"
      } else if (type === "unsplash") {
        this.data.original = false
        this.data.image_source = "Unsplash"
        this.data.image_source_url = "REQUIRED"
        this.data.image_license = "License"
        this.data.image_license_url = "https://unsplash.com/license"
      } else if (type === "wikimedia-commons") {
        this.data.original = false
        this.data.image_source = "Wikimedia Commons"
        this.data.image_source_url = "REQUIRED"
        this.data.image_license = "REQUIRED"
        this.data.image_license_url = "REQUIRED"
        this.data.image_attribution = "REQUIRED: Author Name"
      } else if (type === "clear") {
        this.data.original = false
        this.data.image_source = null
        this.data.image_source_url = null
        this.data.image_license = null
        this.data.image_license_url = null
        this.data.image_attribution = null
      } else if (type === "(adapted)") {
        this.data.original = false
        this.data.image_source = " (adapted)"
        this.data.image_source_url = null
        this.data.image_license = null
        this.data.image_license_url = null
        this.data.image_attribution = null
      }
    },
    onImageChange(file) {
      this.featured_image_preview = file
    },
    async onDelete() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete this image?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`images/${this.data.id}`)) {
          this.$notification.success(`Image successfully deleted.`)
          this.$router.push({ name: "content" })
        }
      }
    },
    onSaveButtonClick() {
      this.errors = null
      this.$refs.form.submit()
    },
    onFormError(response) {
      this.errors = response.errors
    },
    onFormSuccess(response) {
      this.$notification.success("Got it!")
      this.errors = null

      if (this.mode === "add") {
        this.$router.push({ name: "content" })
      } else if (this.mode === "edit") {
        this.data = response.item
      }
    }
  }
}
</script>

<style>
.page-add-edit-image {
  .page-content {
    @apply p-0;
  }

  .form-feedback {
    @apply hidden;
  }

  .content-depths-item {
    @apply ml-0 !important;
    @apply p-0;

    &__icon {
      @apply mr-2 text-lg text-primary-default;
      line-height: 1;
    }

    .form-label {
      @apply flex items-center justify-center rounded border border-gray-400 p-3;
      height: 45px;

      &:hover {
        @apply bg-primary-50;
      }
    }

    .form-check-content {
      @apply ml-0 flex items-center;
    }

    .form-check-custom-input {
      @apply hidden;
    }

    &.is-selected .form-label {
      @apply border-primary-default bg-primary-50 text-primary-default;
    }
  }

  .subtitle {
    @apply mb-6 border-b pb-3 font-medium text-heading;
  }

  .sticky {
    top: calc(theme("header.height") + theme("spacing.12"));
  }
}
</style>
