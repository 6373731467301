<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Regions", endpoint="regions", :sort="{name: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newRegion()") NEW REGION
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Name", sortable="name") {{ row.name }}
          DataTableColumn(title="Countries") {{ row.countries | commaSeparated }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editRegion(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteRegion(row)") Delete
</template>

<script>
import NewRegionModal from "@/modals/Regions/NewRegionModal"
import EditRegionModal from "@/modals/Regions/EditRegionModal"
import BooleanIndicator from "@/components/BooleanIndicator"

export default {
  components: {
    BooleanIndicator
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Regions"
  }),
  methods: {
    async newRegion() {
      if (await this.$modal.show(NewRegionModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editRegion(id) {
      if (await this.$modal.show(EditRegionModal, { endpoint: `regions/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteRegion(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`regions/${row.id}`)) {
          this.$notification.success(`Region **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
