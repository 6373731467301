<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Subject Area & Characteristics", endpoint="tags", :sort="{tag: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newSubjectArea()") NEW SUBJECT AREA
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Subject Area", sortable="tag")
            div.subject-area
              MediaIcon(:media="row.featured_image", :blank="true")
              span {{ row.tag }}
          DataTableColumn(title="content", icon="content", width="max", sortable="content_count") {{ row.content_count | number | default('0') }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editSubjectArea(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteSubjectArea(row)") Delete
</template>

<script>
import NewSubjectAreaModal from "@/modals/SubjectAreas/NewSubjectAreaModal"
import EditSubjectAreaModal from "@/modals/SubjectAreas/EditSubjectAreaModal"
import { MediaIcon } from "@syntax51/app-kit"

export default {
  components: {
    MediaIcon
  },
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-subject-areas" } }
  },
  data: () => ({
    title: "Subject Areas"
  }),
  methods: {
    async newSubjectArea() {
      if (await this.$modal.show(NewSubjectAreaModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editSubjectArea(id) {
      if (await this.$modal.show(EditSubjectAreaModal, { endpoint: `tags/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteSubjectArea(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.tag}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`tags/${row.id}`)) {
          this.$notification.success(`Subject area **${row.tag}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>

<style>
.page-subject-areas {
  .subject-area {
    @apply flex items-center;

    .media-icon {
      @apply mr-3;
    }
  }
}
</style>
