<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Facades", endpoint="facades", :sort="{term: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newFacade()") NEW FACADE
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Facade", sortable="term") {{ row.term }}
          DataTableColumn(title="Destination") {{ row.destination && row.destination.label }}
          DataTableColumn(title="Type", sortable="type", width="max") {{ row.type | ucwords }}
          DataTableColumn(title="Views", icon="eye", sortable="views", width="max") {{ row.views | number }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editFacade(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteFacade(row)") Delete
</template>

<script>
import NewFacadeModal from "@/modals/Facades/NewFacadeModal"
import EditFacadeModal from "@/modals/Facades/EditFacadeModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Facades"
  }),
  methods: {
    async newFacade() {
      if (await this.$modal.show(NewFacadeModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editFacade(id) {
      if (await this.$modal.show(EditFacadeModal, { endpoint: `facades/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteFacade(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.term}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`facades/${row.id}`)) {
          this.$notification.success(`Facade **${row.term}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
