<template>
  <FormModal
    v-if="data"
    title="Edit Site Redirect"
    method="PUT"
    :endpoint="`partners/${partner_id}/sites/${site_id}/redirects/${redirect_id}`"
    :data="data"
    v-bind="$attrs"
    @success="success"
  >
    <InputGroup
      label="Path Pattern"
      name="path_pattern"
      description="Relative path without protocol or domain. Supports wildcard segments, i.e. `*/*/cruises`"
    >
      <TextInput v-model="data.path_pattern" />
    </InputGroup>

    <InputGroup label="Redirect URL" name="redirect_url" description="Relative path without protocol or domain.">
      <TextInput v-model="data.redirect_url" />
    </InputGroup>

    <InputGroup label="Exact Match" name="use_exact_match">
      <ToggleSwitch v-model="data.use_exact_match" label="Use exact match to ignore partial matches" />
    </InputGroup>

    <InputGroup label="Ignore Previous Page" name="ignore_previous_page">
      <ToggleSwitch v-model="data.ignore_previous_page" label="..." />
    </InputGroup>

    <InputGroup label="Enabled" name="enabled">
      <ToggleSwitch v-model="data.enabled" label="Enable this redirect" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    partner_id: {
      type: Number,
      required: true
    },
    site_id: {
      type: Number,
      required: true
    },
    redirect_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: {
      path_pattern: null,
      redirect_url: null,
      use_exact_match: false,
      ignore_previous_page: false,
      enabled: true
    }
  }),
  async mounted() {
    let response = await this.$api.get(
      `partners/${this.partner_id}/sites/${this.site_id}/redirects/${this.redirect_id}`
    )
    this.data = response.data.data
    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Redirect successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
