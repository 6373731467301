<template>
  <AppLayout :title="title">
    <DataTable ref="dataTable" :sort="{ name: 'asc' }" endpoint="host-agencies" searchable title="Host Agencies">
      <template v-slot:buttons>
        <Button type="primary" @click="newHostAgency()">NEW HOST AGENCY</Button>
      </template>
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn sortable="name" title="Name">{{ row.name }}</DataTableColumn>
          <template v-slot:actions>
            <Dropdown class="flip-menu" icon="ellipsis-v">
              <DropdownLink icon="pencil" @click="editHostAgency(row.id)">Edit</DropdownLink>
              <DropdownLink icon="trash" @click="deleteHostAgency(row)">Delete</DropdownLink>
            </Dropdown>
          </template>
        </DataTableRow>
      </template>
    </DataTable>
  </AppLayout>
</template>

<script>
import NewHostAgencyModal from "@/modals/HostAgencies/NewHostAgencyModal"
import EditHostAgencyModal from "@/modals/HostAgencies/EditHostAgencyModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Host Agencies"
  }),
  methods: {
    async newHostAgency() {
      if (await this.$modal.show(NewHostAgencyModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editHostAgency(id) {
      if (await this.$modal.show(EditHostAgencyModal, { endpoint: `host-agencies/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteHostAgency(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`host-agencies/${row.id}`)) {
          this.$notification.success(`Host agency **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
