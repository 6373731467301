<template>
  <DataTable
    ref="dataTable"
    title="Sites"
    :endpoint="`partners/${partner.id}/sites`"
    :sort="{ name: 'asc' }"
    searchable
  >
    <template v-slot:buttons>
      <Button type="primary" @click="newSite()">NEW SITE</Button>
    </template>

    <template v-slot:rows="rows">
      <DataTableRow v-for="(site, i) in rows" :key="i" :row="site">
        <DataTableColumn title="Domain(s)" sortable="domain">
          <p>{{ site.domain }}</p>
          <p class="text-supplement">{{ site.copilot_domains ? site.copilot_domains.join(", ") : "-" }}</p>
        </DataTableColumn>
        <template v-slot:actions>
          <router-link :to="{ name: 'partners.view.site', params: { id: partner.id, site_id: site.id } }">
            <Icon icon="chevron-right" />
          </router-link>
        </template>
      </DataTableRow>
    </template>
  </DataTable>
</template>

<script>
import NewPartnerSiteModal from "@/modals/Partners/NewPartnerSiteModal.vue"

export default {
  metaInfo() {
    return { title: `${this.partner.name} Copilot` }
  },
  props: {
    partner: null
  },
  methods: {
    async newSite() {
      if (
        await this.$modal.show(NewPartnerSiteModal, {
          partner_id: this.partner.id
        })
      ) {
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>
