<template lang="pug">
  AppLayout(:title="title")
    div(v-if="highlights", class=" grid grid-cols-3 gap-x-6 lg:gap-x-12 space-y-0")
      Highlight(label="Total Advisors", :count="highlights.now.advisors_count", :previous-count="highlights.previous.advisors_count" difference-label="growth in the last 30 days")
      Highlight(label="New Advisors", :count="highlights.now.new_advisors_count", :previous-count="highlights.previous.new_advisors_count" difference-label="change from previous 30 days")
      Highlight(label="Active Advisors", :count="highlights.now.active_advisors_count", :previous-count="highlights.previous.active_advisors_count" difference-label="change from previous 30 days")

    DataTable(ref="dataTable", title="Users", endpoint="users", :filters="filters", :params="params", :sort="{created_at: 'desc'}", searchable, class="mt-6 lg:mt-12")
      template(v-slot:buttons)
        Button(type="primary", @click="newUser()") NEW USER
      template(v-slot:filters)
        DataTableFilter(v-model="filters.partner_id", endpoint="options/partners", label="Partner", prepend-option="All Partners")
        DataTableFilter(v-model="filters.accountType", endpoint="options/account-types", label="Account Type", prepend-option="All Account Types")
      template(v-slot:tabs)
        DataTableTabs(v-model="params.filters.type")
          DataTableTab(value="all") All Users
          DataTableTab(value="advisors") Advisors
          DataTableTab(value="new-advisors") New Advisors
          DataTableTab(value="active") Active
          DataTableTab(value="inactive") Inactive
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="User", sortable="first_name")
            div.flex.items-center
              Avatar.mr-5(:label="row.initials", :background-color="row.initials && row.partner && row.partner.customisations && row.partner.customisations.brand_color", :image-url="row.personal_photo && row.personal_photo.thumb_url", :status="row.status")
              div
                router-link(:to="{name: 'users.view', params: {id: row.id}}")
                  span(v-if="row.full_name", v-html="highlight(row.full_name)")
                  span(v-else) Unknown
                div.text-supplement(v-html="highlight(row.email)")
          DataTableColumn(title="Type")
            div(v-if="row.partner")
              router-link(:to="{name: 'partners.view', params: {id: row.partner.id}}", v-html="highlight(row.partner.name)")
              div.text-supplement(v-if="row.user_template") {{ row.user_template.name }}
            div.text-sm.text-supplement(v-else) Advisor
          DataTableColumn(title="Params")
            div #[CopyToClipboardIcon.mr-1(:content="`?insider=${row.insider_param}`")] Insider
            div(v-if="row.partner && row.partner.name") #[CopyToClipboardIcon.mr-1(:content="row.alt_id")] UID
          DataTableColumn(title="Country", sortable="country_id", width="max") {{ row.country && row.country.alpha2.toUpperCase() }}
          DataTableColumn(title="Profile", width="max")
            div(class="relative h-2 w-10 overflow-hidden rounded-full")
              div(class="absolute inset-0 bg-gray-200")
              div(class="absolute inset-y-0 left-0 bg-pink-600" :style="{ width: `${row.profile_completion_percentage}%` }")
          DataTableColumn(title="Last Active", sortable="active_at", sort-order="desc", width="max") {{ row.active_at_human }}
          DataTableColumn(title="Signed Up", sortable="created_at",  sort-order="desc", width="max") {{ row.created_at | datetime }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              //DropdownLink(icon="magic", @click="impersonateUser(row)") Impersonate
              DropdownLink(icon="pencil", @click="editUser(row)") Edit
              DropdownLink(icon="lock", @click="changeUserPassword(row)") Change Password
              DropdownLink(icon="trash", @click="deleteUser(row)") Delete
</template>

<script>
import Avatar from "@/components/Avatar.vue"
import NewUserModal from "@/modals/Users/NewUserModal"
import EditUserModal from "@/modals/Users/EditUserModal.vue"
import ChangeUserPasswordModal from "@/modals/Users/ChangeUserPasswordModal"
import Highlight from "@/components/Highlight.vue"

export default {
  components: {
    Avatar,
    Highlight
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Users",
    highlights: null,
    filters: {},
    params: {
      filters: {
        query: "",
        type: "all"
      }
    }
  }),
  async created() {
    const { data } = await this.$api.get("users/highlights")
    this.highlights = data
  },
  methods: {
    async newUser() {
      if (await this.$modal.show(NewUserModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editUser(user) {
      if (await this.$modal.show(EditUserModal, { endpoint: `users/${user.id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async changeUserPassword(user) {
      if (await this.$modal.show(ChangeUserPasswordModal, { user_id: user.id })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteUser(user) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${user.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`users/${user.id}`)) {
          this.$notification.success(`User **${user.full_name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    },
    highlight(value) {
      return this.$options.filters.highlight(value, this.params.filters.query)
    }
  }
}
</script>
