<template lang="pug">
  div(ref="results")
    Breadcrumbs(:items="breadcrumbs", v-show="breadcrumbs.length > 1")
    div.collection-browser
      div.item(v-for="item in data.items", :key="item.id", :class="`item--${item.type}-access`")
        div.item-details
          div.item-icon(:class="`item-icon-${item.entity}`"): Icon(:icon="item.entity === 'folder' ? 'folder' : 'collection'", prefix="fas")
          div
            div.item-name: router-link(:to="itemLink(item)") {{ item.name }}
            ul.meta
              li.meta__item(v-if="item.entity === 'folder' && item.folders_count") {{ item.folders_count }} {{ item.folders_count === 1 ? 'folder' : 'folders' }}
              li.meta__item(v-if="item.entity === 'folder' && item.collections_count") {{ item.collections_count }} {{ item.collections_count === 1 ? 'collection' : 'collections' }}
              li.meta__item(v-if="item.entity === 'folder' && !item.folders_count && !item.collections_count") Empty
              li.meta__item(v-if="item.entity === 'collection'") {{ item.contents_count }} {{ item.contents_count === 1 ? 'item' : 'items' }}
        div.item-action(v-if="item.entity === 'collection'")
          Dropdown.flip-menu(icon="eye")
            DropdownLink(icon="browser", :href="item.landing_page_url", target="_blank") View Live
            DropdownLink(icon="link", v-clipboard="item.landing_page_url", v-clipboard:success="onCopy") Copy Link
        div.item-action
          router-link(:to="itemLink(item)"): Icon(icon="chevron-right")
    Pagination.mt-6(:pagination="data.meta", @page-changed="onPageChange")
</template>

<script>
import { Breadcrumbs, Pagination } from "@syntax51/app-kit"
import { each, get } from "lodash-es"

export default {
  metaInfo() {
    return { title: `${this.partner.name} Collections`, bodyAttrs: { class: "page-partners-view-collections" } }
  },
  components: {
    Breadcrumbs,
    Pagination
  },
  props: {
    partner: {}
  },
  data: () => ({
    loading: true,
    data: {},
    page: 1
  }),
  watch: {
    $route: "fetch"
  },
  created() {
    this.fetch()
  },
  computed: {
    breadcrumbs() {
      let crumbs = get(this.data, "folder.breadcrumbs", [])
      let breadcrumbs = [
        { label: "Collections", to: { name: "partners.view.collections", params: { id: this.partnerId } } }
      ]

      each(crumbs, breadcrumb => {
        let crumb = { label: breadcrumb.name }
        if (!breadcrumb.last)
          crumb.to = { name: "partners.view.collections", params: { id: this.partnerId, folder_id: breadcrumb.id } }
        breadcrumbs.push(crumb)
      })

      return breadcrumbs
    },
    folderId() {
      return this.$route.params.folder_id || ""
    },
    partnerId() {
      return this.$route.params.id
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      let response = await this.$api.get(`partners/${this.partnerId}/collections-folders/${this.folderId}`, {
        params: { page: this.page }
      })
      this.data = get(response, "data", {})
      this.loading = false
    },
    itemLink(item) {
      let routeName = item.entity === "folder" ? "partners.view.collections" : "collections.view"
      let routeParams = item.entity === "folder" ? { id: this.partnerId, folder_id: item.id } : { id: item.id }
      return { name: routeName, params: routeParams }
    },
    onPageChange({ page }) {
      this.page = page
      this.fetch()
      // this.$refs.results.scrollIntoView()
    },
    onCopy() {
      this.$notification.success("Copied!")
    }
  }
}
</script>

<style>
@import "@syntax51/app-kit/assets/styles/mixins/list-comma-separated.css";

.page-partners-view-collections {
  .breadcrumbs {
    @apply mb-4;
  }

  .collection-browser {
    .card {
      @apply rounded-none bg-transparent shadow-none;
    }

    .item {
      @apply flex items-center rounded-lg bg-white p-8 shadow;

      & + .item {
        @apply mt-3;
      }

      &-details {
        @apply mr-1 flex flex-grow;
      }

      &-name {
        @apply font-semibold;
      }

      &-icon {
        @apply mr-6 flex items-center justify-center rounded-lg text-lg text-supplement;

        &-folder {
          @apply text-2xl;
        }
      }

      &-action {
        @apply ml-1;

        > a,
        > .dropdown {
          @apply flex items-center justify-center;
          height: 30px;
          width: 30px;
        }
      }
    }

    .meta {
      @apply text-supplement;
      @mixin list-comma-separated;

      .icon {
        @apply mr-2;
      }

      &__item {
        @apply mt-1;
      }
    }

    .fa-folder {
      color: #6fcff9;
    }
  }
}
</style>
