<template>
  <FormModal title="Edit Country" :endpoint="endpoint" method="PUT" :data="data" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" />
    </InputGroup>

    <div class="grid grid-cols-2 gap-x-8">
      <InputGroup label="Alpha2 Code" name="alpha2" class="mb-0">
        <TextInput v-model="data.alpha2" />
      </InputGroup>

      <InputGroup label="Alpha3 Code" name="alpha3" class="mb-0">
        <TextInput v-model="data.alpha3" />
      </InputGroup>
    </div>
  </FormModal>
</template>

<script>
import { EditModalMixin } from "@syntax51/app-kit"

export default {
  mixins: [EditModalMixin],
  data: () => ({
    data: {
      name: "",
      alpha2: "",
      alpha3: ""
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`**${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
