<template lang="pug">
  Modal(title="Add Content To Collection", v-bind="$attrs")
    DataTable(ref="dataTable", title="Content", endpoint="content", :per-page="10", :sort="{title: 'asc'}", searchable)
      template(v-slot:rows="rows")
        DataTableRow(v-for="row in rows", :key="row.id", :row="row")
          DataTableColumn(title="Title", sortable="title") {{ row.title }}
          DataTableColumn(title="Added", sortable="created_at", width="max") {{ row.created_at | datetime }}
          template(v-slot:actions)
            Button(v-if="content_ids.indexOf(row.id) == -1", size="sm", type="primary", @click="addToCollection(row)"): Icon(icon="plus")
            Button(v-else, size="sm", type="success", @click="removeFromCollection(row)"): Icon(icon="check")
</template>

<script>
import { get, merge } from "lodash-es"

export default {
  props: {
    collection: Object
  },
  async mounted() {
    let response = await this.$api.get(`collections/${this.collection.id}/content/ids`)
    merge(this.content_ids, get(response, "data.items", []))
  },
  data: () => ({
    content_ids: []
  }),
  methods: {
    async addToCollection(content) {
      if (this.content_ids.indexOf(content.id) !== -1) {
        return
      }

      await this.$api.post(`collections/${this.collection.id}/content`, { content_ids: [content.id] })
      this.$notification.success(`**${content.title}** has been added to this collection.`)
      this.content_ids.push(content.id)
    },
    async removeFromCollection(content) {
      let idx = this.content_ids.indexOf(content.id)
      if (idx > -1) {
        this.content_ids.splice(idx, 1)
        await this.$api.post(`collections/${this.collection.id}/content/remove`, { content_ids: [content.id] })
        this.$notification.success(`**${content.title}** has been removed from this collection.`)
      }
    }
  }
}
</script>
