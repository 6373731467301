import store from "store2"
const persist = store.namespace("sidebar")

export default {
  namespaced: true,
  state: {
    style: persist.get("style") || "default"
  },
  mutations: {
    toggle(state) {
      let style = state.style === "default" ? "compact" : "default"
      state.style = style
      persist.set("style", style)
    }
  },
  actions: {
    toggle({ commit }) {
      commit("toggle")
    }
  },
  getters: {
    style: state => state.style
  }
}
