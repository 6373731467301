import Vue from "vue"
import { AppKit } from "@syntax51/app-kit"
import { apiPrefix } from "./config"

Vue.use(AppKit)

/**
 * Global Styles
 */
import "@syntax51/app-kit/assets/styles/appkit.css"
import "@syntax51/app-kit/assets/styles/inter.css"
import "@syntax51/app-kit/assets/styles/tailwind.css"

/**
 * Components
 */
import { Button, Card, Column, CopyToClipboardIcon, Row } from "@syntax51/app-kit"

Vue.component("Button", Button)
Vue.component("Card", Card)
Vue.component("Column", Column)
Vue.component("CopyToClipboardIcon", CopyToClipboardIcon)
Vue.component("Row", Row)

/**
 * Packages
 */
import {
  DataTablePackage,
  DropdownPackage,
  FiltersPackage,
  FormPackage,
  NavigationPackage,
  TablePackage
} from "@syntax51/app-kit"

Vue.use(DataTablePackage)
Vue.use(DropdownPackage)
Vue.use(FiltersPackage)
Vue.use(FormPackage)
Vue.use(NavigationPackage)
Vue.use(TablePackage)

/**
 * Plugins
 */
import { ApiPlugin, ModalPlugin, NotificationPlugin, TooltipPlugin } from "@syntax51/app-kit"

Vue.use(ApiPlugin, { prefix: apiPrefix })
Vue.use(ModalPlugin)
Vue.use(NotificationPlugin)
Vue.use(TooltipPlugin)
