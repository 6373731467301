<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Destinations", endpoint="destinations", :sort="{label: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newDestination()") NEW DESTINATION
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Destination", sortable="label") {{ row.label }}
          DataTableColumn(icon="content", width="max", sortable="content_count") {{ row.content_count | number | default('0') }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editDestination(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteDestination(row)") Delete
</template>

<script>
import NewDestinationModal from "@/modals/Destinations/NewDestinationModal"
import EditDestinationModal from "@/modals/Destinations/EditDestinationModal"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Destinations"
  }),
  methods: {
    async newDestination() {
      if (await this.$modal.show(NewDestinationModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editDestination(id) {
      if (await this.$modal.show(EditDestinationModal, { endpoint: `destinations/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteDestination(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`destinations/${row.id}`)) {
          this.$notification.success(`Destination **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>

<style>
.page-destinations-index {
  .language {
    @apply flex items-center;
  }
}
</style>
