<template lang="pug">
  FormModal(title="New Partner", endpoint="partners", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Account Type", name="account_type_id"): Select(v-model="data.account_type_id", endpoint="options/account-types", prepend-icon="user-circle")
    InputGroup(label="Name", name="name"): TextInput(v-model="data.name", prepend-icon="user", @input="onNameChange")
    InputGroup(label="Slug", name="slug"): TextInput(v-model="data.slug", prepend-icon="link")
    InputGroup(label="Email", name="email"): TextInput(v-model="data.email", prepend-icon="envelope", placeholder="name@site.com")
    InputGroup(label="Phone", name="phone"): TextInput(v-model="data.phone", prepend-icon="phone", placeholder="+country code + number")
    InputGroup(label="URL", name="url"): TextInput(v-model="data.url", prepend-icon="mouse", placeholder="www.site.com")
    InputGroup(label="Whitelist Domains", name="whitelist_domains", description="Press 'enter' after each domain")
      TagsInput(v-model="data.whitelist_domains", prepend-icon="browser", placeholder="Enter domain...")
    InputGroup(label="Same Tab Domains", name="same_tab_domains", description="Press 'enter' after each domain")
      TagsInput(v-model="data.same_tab_domains", prepend-icon="browser", placeholder="Enter domain...")
    InputGroup(label="Landing Page Domain", name="landing_page_domain"): TextInput(v-model="data.landing_page_domain", prepend-icon="browser")
    InputGroup(label="Lead generation / Surveys", name="has_premium_forms")
      Radios(v-model="data.has_premium_forms", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Itineraries", name="has_premium_itineraries")
      Radios(v-model="data.has_premium_itineraries", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Enhanced SEO Features", name="has_premium_seo")
      Radios(v-model="data.has_premium_seo", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Travel Advisor Marketing", name="has_premium_share")
      Radios(v-model="data.has_premium_share", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
    InputGroup(label="Premium Themes", name="has_premium_themes")
      Radios(v-model="data.has_premium_themes", inline)
        Radio(:value="true") Yes
        Radio(:value="false") No
</template>

<script>
export default {
  data: () => ({
    data: {
      account_type_id: 11,
      name: "",
      slug: "",
      email: "",
      phone: "",
      url: "",
      whitelist_domains: [],
      same_tab_domains: [],
      has_premium_forms: false,
      has_premium_itineraries: false,
      has_premium_seo: false,
      has_premium_share: false,
      has_premium_themes: false
    }
  }),
  methods: {
    onNameChange(name) {
      this.data.slug = name.replace(/\s+/g, "-").toLowerCase()
    },
    success(result) {
      this.$notification.success(`Partner **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
