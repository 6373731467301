<template lang="pug">
  AppLayout(:title="title")
    DataTable(ref="dataTable", title="Media Types", endpoint="content-types", :sort="{order: 'asc'}", searchable)
      template(v-slot:buttons)
        Button(type="primary", @click="newMediaType()") NEW MEDIA TYPE
        Button(type="secondary", @click="reorderMediaTypes()") REORDER
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Media Type", sortable="name")
            div.media-type
              div.media-type__icon: Icon(:icon="row.icon")
              div
                p.media-type__name {{ row.name }}
                p {{ row.description }}
          DataTableColumn(title="Lead In", width="max", sortable="lead_in") {{ row.lead_in }}
          DataTableColumn(title="CTA", width="max", sortable="call_to_action") {{ row.call_to_action }}
          DataTableColumn(title="Content", icon="content", width="max", sortable="content_count") {{ row.content_count | number | default('0') }}
          DataTableColumn(title="Weight", icon="weight-hanging", width="max", sortable="weight") {{ row.weight | number | default('0') }}
          DataTableColumn(title="Order", icon="weight-hanging", width="max", sortable="order") {{ row.order | number | default('0') }}
          template(v-slot:actions)
            Dropdown.flip-menu(icon="ellipsis-v")
              DropdownLink(icon="pencil", @click="editMediaType(row.id)") Edit
              DropdownLink(icon="trash", @click="deleteMediaType(row)") Delete
</template>

<script>
import NewMediaTypeModal from "@/modals/MediaTypes/NewMediaTypeModal"
import EditMediaTypeModal from "@/modals/MediaTypes/EditMediaTypeModal"
import ReorderMediaTypesModal from "@/modals/MediaTypes/ReorderMediaTypesModal"

export default {
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-media-types" } }
  },
  data: () => ({
    title: "Media Types"
  }),
  methods: {
    async newMediaType() {
      if (await this.$modal.show(NewMediaTypeModal)) {
        this.$refs.dataTable.reload()
      }
    },
    async editMediaType(id) {
      if (await this.$modal.show(EditMediaTypeModal, { endpoint: `content-types/${id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteMediaType(row) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${row.name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`content-types/${row.id}`)) {
          this.$notification.success(`Media type **${row.name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    },
    async reorderMediaTypes() {
      await this.$modal.show(ReorderMediaTypesModal)
      this.$refs.dataTable.reload()
    }
  }
}
</script>

<style>
.page-media-types {
  .media-type {
    @apply flex items-center;

    &__icon {
      @apply mr-6 w-6 flex-shrink-0 text-center text-xl text-primary-default;
    }

    &__name {
      @apply font-semibold text-heading;
    }
  }
}
</style>
