<template lang="pug">
  AppLayout(:title="title")
    Card(title="Content")
      div.inline-flex.flex-col.space-y-3
        Button(as="a", :href="urls['all-content']", type="primary", prepend-icon="download", v-if="urls['all-content']") All Content
        Button(as="a", :href="urls['content-in-collections']", type="primary", prepend-icon="download", v-if="urls['content-in-collections']") Content in Collections
        Button(as="a", :href="urls['audio-content']", type="primary", prepend-icon="download", v-if="urls['audio-content']") Audio Content
        Button(as="a", :href="urls['video-content']", type="primary", prepend-icon="download", v-if="urls['video-content']") Video Content

    Card(title="Inspires")
      div.inline-flex.flex-col.space-y-3
        Button(as="a", :href="urls['advisors']", type="primary", prepend-icon="download", v-if="urls['advisors']") Advisors
</template>

<script>
import { get } from "lodash-es"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Export Data",
    urls: {}
  }),
  async created() {
    let response = await this.$api.get("export/signed-urls")
    this.urls = get(response, "data.signed_urls", {})
  }
}
</script>
