<template lang="pug">
  FormModal.modal-duplicate-collection(title="Duplicate Collection", :endpoint="`collections/${existingCollectionId}/duplicate`", :data="data", v-bind="$attrs", @success="success")
    p.description You are about to duplicate #[strong {{ existingCollectionName }}] to a new collection along with all of its content. Please choose which folder you would like the newly duplicated collection placed under, as well as a unique name.

    InputGroup(label="Privacy", name="type")
      Radios(v-model="data.type", inline)
        Radio(value="partner", @change="onTypeChange()")
          Icon(icon="users")
          div
            div: strong Shared
            div.text-supplement Shared among all members of a team.
        Radio(value="personal", @change="onTypeChange()")
          Icon(icon="user")
          div
            div: strong Personal
            div.text-supplement Private and visible only to the user.

    InputGroup(label="Name", name="name"): TextInput(v-model="data.name")

    InputGroup(label="Partner", name="partner_id")
      Select(v-model="data.partner_id", endpoint="options/partners", prepend-icon="users")

    InputGroup(v-if="data.type === 'personal' && data.partner_id", label="User", name="user_id")
      Select(v-model="data.user_id", :endpoint="`options/partners/${data.partner_id}/users`", prepend-icon="user")

    InputGroup(v-if="data.type === 'partner' && data.partner_id", label="Folder", name="folder_id")
      Select(v-model="data.folder_id", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas", :endpoint="`partners/${data.partner_id}/folders`", option-label="label")

    InputGroup(v-else-if="data.type === 'personal' && data.user_id", label="Folder", name="folder_id")
      Select(v-model="data.folder_id", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas", :endpoint="`users/${data.user_id}/folders`", option-label="label")
</template>

<script>
export default {
  props: {
    existingCollectionId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    existingCollectionName: "",
    data: {
      type: "partner",
      partner_id: null,
      user_id: null,
      name: "",
      folder_id: ""
    }
  }),
  async created() {
    let response = await this.$api.get(`collections/${this.existingCollectionId}`)
    this.existingCollectionName = response.data.item.name
    this.data.type = response.data.item.type
  },
  methods: {
    onTypeChange() {
      this.data.user_id = null
      this.data.folder_id = null
    },
    success(result) {
      this.$notification.success(`Collection **${this.data.name}** successfully duplicated.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.modal-duplicate-collection {
  .description {
    @apply mb-10;
  }
}
</style>
